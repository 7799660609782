/*
Content Query
 
Description: A mass improvement on how to design components with alteration based on the container dimensions rather than the page width. In attempts to reduce the increase in size of the style sheet container queries are only reserved to responsive class properties.

Markup:
<div class="warpper\@container">
</div>



Weight:123
 
Styleguide Layouts.ContentQuery

*/
/*
Content Query Reactive Styles
 
Description: <table>
<thead>
<tr>
<th>Style</th>
<th>Short Name</th>
<th>CSS Property</th>
</tr>
</thead>
<tbody>
<tr>
<td>display</td>
<td>'block,flex...'</td>
<td>'display'</td>
</tr>
<tr>
<td>position</td>
<td>'absolute,relative...'</td>
<td>'position'</td>
</tr>
<tr>
<td>grid</td>
<td>'.columns','.rows'</td>
<td>'grid-column-template' ,'grid-row-template'</td>
</tr>
<tr>
<td>flex-size</td>
<td>'.flex'</td>
<td>'flex'</td>
</tr>
<tr>
<td>flex-direction</td>
<td>'.flex'</td>
<td>'flex-direction'</td>
</tr>
<tr>
<td>gap</td>
<td>'.gap'</td>
<td>'gap'</td>
</tr>
<tr>
<td>justify</td>
<td>'.justify'</td>
<td>'justify-content' ,'justify-items','justify-self'</td>
</tr>
<tr>
<td>align</td>
<td>'.content','.items','.self'</td>
<td>'align-content' ,'align-items','align-self'</td>
</tr>
<tr>
<td>margin</td>
<td>'.m'</td>
<td>'margin'</td>
</tr>
<tr>
<td>padding</td>
<td>'.p'</td>
<td>'padding'</td>
</tr>
<tr>
<td>font-size</td>
<td>'.font'</td>
<td>'font-size'</td>
</tr>
<tr>
<td>border-style</td>
<td>'.br'</td>
<td>'border-style'</td>
</tr>
</tbody>
</table>




Weight:123
 
Styleguide Layouts.ContentQuery.Classes

*/


.wrapper-container {
  container-type: inline-size;
  container-name:querywrapper;
}

@container ( min-width: 0px ) {
  /* Your small (sm) styles here */
  /* display */
    /* grid*/
  .row_all {
    grid-row-start: start;
    grid-row-end: end;
  }
  .col_all{
    grid-column-start: start;
    grid-column-end: end;
  }
  @include loop-style(display,"");
  @include loop-style(position,"");
  /*Border Styles*/
  @include loop-style(border-style,"");
  /*Font Styles*/
  @include loop-style(font-size,""," !important");
  @include loop-style(text-align,"", " !important");
  /* position placements  */
  @include loop-style(inset,"", "");
  @include loop-style(top,"", "");
  @include loop-style(bottom,"", "");
  @include loop-style(left,"", "");
  @include loop-style(right,"", "");
  /*flex*/
  @include loop-style(flex,"");
  @include loop-style(flex-direction,"");
  @include loop-style(flex-wrap,"");
  @include loop-style(flex-basis,"");
  @include loop-style(justify-content,"");
  @include loop-style(justify-items,"");
  @include loop-style(justify-self,"");
  @include loop-style(align-content,"");
  @include loop-style(align-items,"");
  @include loop-style(align-self,"");

  @include loop-style(grid-template-columns,"");
  @include loop-style(grid-template-rows,"");
  @include loop-style(grid-gap,"");
  @include loop-style(grid-column-gap,"");
  @include loop-style(grid-row-gap,"");
  @include loop-style(grid-column-start,"");
  @include loop-style(grid-column-end,"");
  @include loop-style(grid-row-start,"");
  @include loop-style(grid-row-end,"");
  @include loop-style(margin,"", " !important");
  @include loop-style(margin-vertical,"", " !important");
  @include loop-style(margin-horizontal,"", " !important");
  @include loop-style(margin-top,"", " !important");
  @include loop-style(margin-bottom,"", " !important");
  @include loop-style(margin-left,"", " !important");
  @include loop-style(margin-right,"", " !important");
  @include loop-style(padding,"", " !important");
  @include loop-style(padding-vertical,"", " !important");
  @include loop-style(padding-horizontal,"", " !important");
  @include loop-style(padding-top,"", " !important");
  @include loop-style(padding-bottom,"", " !important");
  @include loop-style(padding-left,"", " !important");
  @include loop-style(padding-right,"", " !important");
}
/* Small (sm) */
@container ( min-width: 320px ) {
  /* Your small (sm) styles here */
  /* display */
    /* grid*/
  .row_all\:sm {
    grid-row-start: start;
    grid-row-end: end;
  }
  .col_all\:sm{
    grid-column-start: start;
    grid-column-end: end;
  }
  @include loop-style(display, $bp-label_small);
  @include loop-style(position, $bp-label_small);
  /*Border Styles*/
  @include loop-style(border-style, $bp-label_small);
  /*Font Styles*/
  @include loop-style(font-size, $bp-label_small," !important");
  @include loop-style(text-align, $bp-label_small, " !important");
  /* position placements  */
  @include loop-style(inset, $bp-label_small, "");
  @include loop-style(top, $bp-label_small, "");
  @include loop-style(bottom, $bp-label_small, "");
  @include loop-style(left, $bp-label_small, "");
  @include loop-style(right, $bp-label_small, "");
  /*flex*/
  @include loop-style(flex, $bp-label_small);
  @include loop-style(flex-direction, $bp-label_small);
  @include loop-style(flex-wrap, $bp-label_small);
  @include loop-style(flex-basis, $bp-label_small);
  @include loop-style(justify-content, $bp-label_small);
  @include loop-style(justify-items, $bp-label_small);
  @include loop-style(justify-self, $bp-label_small);
  @include loop-style(align-content, $bp-label_small);
  @include loop-style(align-items, $bp-label_small);
  @include loop-style(align-self, $bp-label_small);

  @include loop-style(grid-template-columns, $bp-label_small);
  @include loop-style(grid-template-rows, $bp-label_small);
  @include loop-style(grid-gap, $bp-label_small);
  @include loop-style(grid-column-gap, $bp-label_small);
  @include loop-style(grid-row-gap, $bp-label_small);
  @include loop-style(grid-column-start, $bp-label_small);
  @include loop-style(grid-column-end, $bp-label_small);
  @include loop-style(grid-row-start, $bp-label_small);
  @include loop-style(grid-row-end, $bp-label_small);
  @include loop-style(margin, $bp-label_small, " !important");
  @include loop-style(margin-vertical, $bp-label_small, " !important");
  @include loop-style(margin-horizontal, $bp-label_small, " !important");
  @include loop-style(margin-top, $bp-label_small, " !important");
  @include loop-style(margin-bottom, $bp-label_small, " !important");
  @include loop-style(margin-left, $bp-label_small, " !important");
  @include loop-style(margin-right, $bp-label_small, " !important");
  @include loop-style(padding, $bp-label_small, " !important");
  @include loop-style(padding-vertical, $bp-label_small, " !important");
  @include loop-style(padding-horizontal, $bp-label_small, " !important");
  @include loop-style(padding-top, $bp-label_small, " !important");
  @include loop-style(padding-bottom, $bp-label_small, " !important");
  @include loop-style(padding-left, $bp-label_small, " !important");
  @include loop-style(padding-right, $bp-label_small, " !important");
}

/* Medium (md) */
@container ( min-width: 540px ) {
  /* Your medium (md) styles here */
  @include loop-style(display, $bp-label_medium);
  @include loop-style(position, $bp-label_medium);
  /*Border Styles*/
  @include loop-style(border-style, $bp-label_medium);
  /*Font Styles*/
  @include loop-style(font-size, $bp-label_medium," !important");
  @include loop-style(text-align, $bp-label_medium, " !important");
  /* position placements  */
  @include loop-style(inset, $bp-label_medium, "");
  @include loop-style(top, $bp-label_medium, "");
  @include loop-style(bottom, $bp-label_medium, "");
  @include loop-style(left, $bp-label_medium, "");
  @include loop-style(right, $bp-label_medium, "");
  /*flex*/
  @include loop-style(flex, $bp-label_medium);
  @include loop-style(flex-direction, $bp-label_medium);
  @include loop-style(flex-wrap, $bp-label_medium);
  @include loop-style(flex-basis, $bp-label_medium);
  @include loop-style(justify-content, $bp-label_medium);
  @include loop-style(justify-items, $bp-label_medium);
  @include loop-style(justify-self, $bp-label_medium);
  @include loop-style(align-content, $bp-label_medium);
  @include loop-style(align-items, $bp-label_medium);
  @include loop-style(align-self, $bp-label_medium);
  /* grid*/
  .row_all\:md{
    grid-row-start: start;
    grid-row-end: end;
  }
  .col_all\:md {
    grid-column-start: start;
    grid-column-end: end;
  }
  @include loop-style(grid-template-columns, $bp-label_medium);
  @include loop-style(grid-template-rows, $bp-label_medium);
  @include loop-style(grid-gap, $bp-label_medium);
  @include loop-style(grid-column-gap, $bp-label_medium);
  @include loop-style(grid-row-gap, $bp-label_medium);
  @include loop-style(grid-column-start, $bp-label_medium);
  @include loop-style(grid-column-end, $bp-label_medium);
  @include loop-style(grid-row-start, $bp-label_medium);
  @include loop-style(grid-row-end, $bp-label_medium);
  @include loop-style(margin, $bp-label_medium, " !important");
  @include loop-style(margin-vertical, $bp-label_medium, " !important");
  @include loop-style(margin-horizontal, $bp-label_medium, " !important");
  @include loop-style(margin-top, $bp-label_medium, " !important");
  @include loop-style(margin-bottom, $bp-label_medium, " !important");
  @include loop-style(margin-left, $bp-label_medium, " !important");
  @include loop-style(margin-right, $bp-label_medium, " !important");
  @include loop-style(padding, $bp-label_medium, " !important");
  @include loop-style(padding-vertical, $bp-label_medium, " !important");
  @include loop-style(padding-horizontal, $bp-label_medium, " !important");
  @include loop-style(padding-top, $bp-label_medium, " !important");
  @include loop-style(padding-bottom, $bp-label_medium, " !important");
  @include loop-style(padding-left, $bp-label_medium, " !important");
  @include loop-style(padding-right, $bp-label_medium, " !important");
}

/* Large (lg) */
@container ( min-width: 768px ) {
  /* Your large (lg) styles here */
  @include loop-style(display, $bp-label_large);
  @include loop-style(position, $bp-label_large);
  /*Border Styles*/
  @include loop-style(border-style, $bp-label_large);
  /*Font Styles*/
  @include loop-style(font-size, $bp-label_large," !important");
  @include loop-style(text-align, $bp-label_large, " !important");
  /* position placements  */
  @include loop-style(inset, $bp-label_large, "");
  @include loop-style(top, $bp-label_large, "");
  @include loop-style(bottom, $bp-label_large, "");
  @include loop-style(left, $bp-label_large, "");
  @include loop-style(right, $bp-label_large, "");
  /*flex*/
  @include loop-style(flex, $bp-label_large);
  @include loop-style(flex-direction, $bp-label_large);
  @include loop-style(flex-wrap, $bp-label_large);
  @include loop-style(flex-basis, $bp-label_large);
  @include loop-style(justify-content, $bp-label_large);
  @include loop-style(justify-items, $bp-label_large);
  @include loop-style(justify-self, $bp-label_large);
  @include loop-style(align-content, $bp-label_large);
  @include loop-style(align-items, $bp-label_large);
  @include loop-style(align-self, $bp-label_large);
  /* grid*/
  .row_all\:lg{
    grid-row-start: start;
    grid-row-end: end;
  }
  .col_all\:lg{
    grid-column-start: start;
    grid-column-end: end;
  }
  @include loop-style(grid-template-columns, $bp-label_large);
  @include loop-style(grid-template-rows, $bp-label_large);
  @include loop-style(grid-gap, $bp-label_large);
  @include loop-style(grid-column-gap, $bp-label_large);
  @include loop-style(grid-row-gap, $bp-label_large);
  @include loop-style(grid-column-start, $bp-label_large);
  @include loop-style(grid-column-end, $bp-label_large);
  @include loop-style(grid-row-start, $bp-label_large);
  @include loop-style(grid-row-end, $bp-label_large);
  @include loop-style(margin, $bp-label_large, " !important");
  @include loop-style(margin-vertical, $bp-label_large, " !important");
  @include loop-style(margin-horizontal, $bp-label_large, " !important");
  @include loop-style(margin-top, $bp-label_large, " !important");
  @include loop-style(margin-bottom, $bp-label_large, " !important");
  @include loop-style(margin-left, $bp-label_large, " !important");
  @include loop-style(margin-right, $bp-label_large, " !important");
  @include loop-style(padding, $bp-label_large, " !important");
  @include loop-style(padding-vertical, $bp-label_large, " !important");
  @include loop-style(padding-horizontal, $bp-label_large, " !important");
  @include loop-style(padding-top, $bp-label_large, " !important");
  @include loop-style(padding-bottom, $bp-label_large, " !important");
  @include loop-style(padding-left, $bp-label_large, " !important");
  @include loop-style(padding-right, $bp-label_large, " !important");
}
