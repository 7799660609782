@use "sass:string";
/*
List
 
Description: A major part of the construction of the web and the content of the web are lists.

Weight: 110
 
Styleguide List
*/
/*
  List Type

  Change the type of a list.

  .ul_none            -  none
  .ul_bullet          -  bullet          
  .ul_square          -  square      

Markup:
  <div class="font_normal font_1 p_3">
    <h3 class="font_2 font_bold c_shade_3 p-b_3">{{modifier_class}}</h3>
    <ul class="{{modifier_class}}">
    <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
    <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
    <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
    <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
    <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
    </ul>
</div>

Weight: 0

  Styleguide List.Type
 */

// list with no decoration shouldn't have a padding
.ul_none{
    padding: 0;
    margin: 0;
}

@include loop-style(list-type, '', '!important');
@import 'marker';
@import 'counters';


/*
Inline Lists

Inline Lists are for separated items on a single line.

.ul_row.gap_4                -  basic ul with no separators but formatted with flex row
.ul_inline                -  basic inline ul with no separators
.ul_inline-comma            -  wrapping line of text that is separated by commas
.ul_inline-semicolon          -  wrapping line of text that is separated by semicolons          
.ul_inline-pipe          -  wrapping line of text that is separated by pipes     

Markup:
<div class="font_normal font_1 p_3">
  <h3 class="font_2 font_bold c_shade_3 p-b_3">{{modifier_class}}</h3>
  <ul class="{{modifier_class}}">
    <li>Evelyn Watsica II</li>
    <li>Linda Lang</li>
    <li>Laurie Greenfelder</li>
    <li>Katie Wiegand</li>
    <li>Brittany Conn</li>
  </ul>
</div>
<style>
[class^="ul_inline"] > li:last-child::after {
    content:"" !important;
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
}
</style>


NOTE: The limitations of the styleguide around compound pseudo selector :not(:last-child):after means a trailing divider shows up in documentation but not in production applications. Added a workaround to hide the divider via the following style tag in the example.  This is not necessary in production applications.


Weight: 1000

Styleguide List.InlineType

*/


.ul_row{
  padding: 0;
  margin: 0;
  display:  flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  list-style: none;
  & > li {
    flex: 0 1 auto;
    margin:0;  
}

}
.ul_inline{
  @extend .ul_row;
  display:  inline-flex;
}


.ul_inline-comma,
.ul_inline-semicolon,
.ul_inline-pipe{
  @extend .ul_inline;
  & > li {
    &:not(:last-child)::after{
      padding-inline-end: .5em;
      padding-inline-start: 0;
      opacity: .6;
      margin:0;
    }
}
li.no-after{padding-inline-end: .5em;}
li.no-after::after{
  display:none !important;
  
}
}

.ul_inline-comma > li:not(:last-child)::after {
    content: ",";
}

.ul_inline-semicolon > li:not(:last-child)::after {
  content: ";";
}
.ul_inline-pipe > li:not(:last-child)::after {
  content: "|";
  padding-inline-start:.5em;

}
