/*
	Flex

	Structure Objects quickly and change the order of the  

	.flex_row              		- flex_row
	.flex_column              		- flex_column
	.flex_row-reverse              		- flex_column-reverse
	.flex_column-reverse              		- flex_row-reverse

	Markup:
	<div class="flex {{modifier_class}} br_solid br_transparent bg_alert-3  text_center  p_3 bg_warning-3"> 
		<div class="bg_primary-5 text_center flex_auto p_3 br_solid br_1 m_2 br_black">Auto 1</div>
		<div class="bg_primary-5 text_center flex_shrink p_3 br_solid br_1 m_2 br_black">Shrink 2</div>
		<div class="bg_primary-5 text_center flex_auto p_3 br_solid br_1 m_2 br_black">Auto 3</div>
	</div>

	Styleguide Layouts.Display.flex

 */
/* 1. Fix for Chrome 44 bug.
* https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex_auto,.flex_grow,.flex_shrink{
	min-width: 0; /* 1 */
	min-height: 0; /* 1 */
}
.flex > * {
	box-sizing: border-box;
}

	
@include loop-style(flex);
@include loop-style(flex-direction);
@include loop-style(flex-wrap);
@include loop-style(flex-basis);
@include loop-style(justify-content);
@include loop-style(justify-items);
@include loop-style(justify-self);
@include loop-style(align-content);
@include loop-style(align-items);
@include loop-style(align-self);
@include loop-style(order);

@media #{$medium-up} {
	
	@include loop-style(flex, $bp-label_medium);
	@include loop-style(flex-direction, $bp-label_medium);
	@include loop-style(flex-wrap, $bp-label_medium);
	@include loop-style(flex-basis, $bp-label_medium);
	@include loop-style(justify-content, $bp-label_medium);
	@include loop-style(justify-items, $bp-label_medium);
	@include loop-style(justify-self, $bp-label_medium);
	@include loop-style(align-content, $bp-label_medium);
	@include loop-style(align-items, $bp-label_medium);
	@include loop-style(align-self, $bp-label_medium);
	@include loop-style(order, $bp-label_large);

}

@media #{$large-up} {
	
	@include loop-style(flex, $bp-label_large);
	@include loop-style(flex-direction, $bp-label_large);
	@include loop-style(flex-wrap, $bp-label_large);
	@include loop-style(flex-basis, $bp-label_large);
	@include loop-style(justify-content, $bp-label_large);
	@include loop-style(justify-items, $bp-label_large);
	@include loop-style(justify-self, $bp-label_large);
	@include loop-style(align-content, $bp-label_large);
	@include loop-style(align-items, $bp-label_large);
	@include loop-style(align-self, $bp-label_large);
	@include loop-style(order, $bp-label_large);


}
