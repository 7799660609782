// 100
@import 'introduction';
@import 'reading-typography';
@import 'typography';
@import 'lists.scss';
// 105 spacing
@import '_layout-spacing';
/*
Layout 

Padding, Margins, and Positions are the most common usage for Utility Classes.  Layout UC's are responsive and can be postfixed with `:md` and with `:lg` to have finer control on the spacing of objects.

Weight: 120

Styleguide Layouts
*/

// 106 display
// 106 display.type
// 106 display.vertical alignment
@import 'display';
@import 'flex';
@import 'grid';
// 106 Display.Overflow +100
@import 'overflow';
@import 'scroll-snap';
@import 'aspect-ratio';
@import 'width';
@import 'height';
// 110

/*
Decorator 

Colors, textures, logos and other design flourishes.

Weight: 125

Styleguide Decorator
*/

// 120 background
// 120 background.Color -100
// 120 background.Color.Shades
// 120 background.Color.with Alphas
@import 'shadows';
// 120 background.textures
@import 'background';
// 120 background.shadows 100
// 120 background.gradient 120
@import 'textures';
@import 'gradient';
@import "logo";
// 125 border
@import 'border';
// 128 Opacity
@import 'opacity';
// 130 hover
// hover.text 10
// hover.underlines 20
// hover.background 30
// hover.white and black opacity 50
// hover.opacity 60
@import 'is-active';
@import 'hover';
@import 'disabled'; 
// animation 140
@import 'animation';
@import 'rotate';
// blend 150
@import 'blend';


/*
Interaction

Modify how the user gets feed back of changes from hover effects to active states and how things animate or select.

Weight: 1000

Styleguide Interaction.States
*/

@import "interacitve-extras/index";

/*
States

Prefixed before class rules the h: for hover or a: for active class allows for some dynamics with very little efforts. 


Weight: -100

Styleguide Interaction.States
*/

// 150 counters


@import 'print';
@import 'user-select';
@import 'cursor';

@import 'contentquery';
/*
Extras
 
Extras include user modification and other classes that modify the user interactions and experiences 
 
Weight: 1000
 
Styleguide Interaction.User
*/

.btn_acc-primary{
    @extend .ease_out !optional;
    @extend .transition_1 !optional;
    @extend .text_center !optional;
    @extend .br_none !optional;
    @extend .inline-block !optional;
    @extend .w_auto !optional;
    @extend .font_medium !optional;
    @extend .p-y_3 !optional;
    @extend .lh_2 !optional;
    @extend .p-x_4 !optional;
    @extend .font_1 !optional;
    @extend .c_white !optional;
    @extend .br_primary-n3 !optional;
    @extend .bg_primary !optional;
    @extend .br_radius !optional;
    @extend .h\:bg_primary-n2 !optional;
    @extend .f\:outline_none !optional;
    @extend .h\:c_white !optional;
    @extend .font_2\:md !optional;
 }
 

 .btn.disabled, button.btn.disabled, input[type="button"].btn.disabled, input[type="reset"].btn.disabled, input[type="submit"].btn.disabled, .btn_acc-primary.disabled {
    cursor: not-allowed !important;
    @extend .bg_black-3 !optional;
    @extend .br_black-2 !optional;
    @extend .c_black-5 !optional;
    box-shadow: none !important;
}



