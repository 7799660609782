/*
  Hover:Border Color
 
  hovers can easily effect the border style to toggle between styles.  Very useful fo toggling from a border type to none or vice versa. 
| Class          | Effect                                                                                      |
| -------------- | ------------------------------------------------------------------------------------------- |
| cursor_pointer        | pointer                                                                     |
| cursor_not-allowed      | no touch                                                                   |
| cursor_grab      | open hand                                                                   |
| cursor_grabbing      | closed hand                                                                   |
| cursor_wait | spinning ball |


Weight: 100

Styleguide Interaction.States.Hover.cursor

*/
.cursor_pointer {
	cursor: pointer;
}
.cursor_not-allowed {
	cursor: not-allowed;
}
.cursor_grab {
	cursor: grab;
}
.cursor_grabbing {
	cursor: grabbing;
}
.cursor_wait {
	cursor: wait;
}