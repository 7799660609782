/*
Width 

Control some basics of the design with max-width and width.   

Control the width percents.  
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> [modifier class] <div class="bg_primary p-y_2 text-left [modifier class] m-t_3"> &nbsp; </div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_auto <div class="bg_primary p-y_2 text-left w_auto m-t_3"> &nbsp; </div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_0 <div class="bg_primary p-y_2 text-left w_0 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_5 <div class="bg_primary p-y_2 text-left w_5 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_10 <div class="bg_primary p-y_2 text-left w_10 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_15 <div class="bg_primary p-y_2 text-left w_15 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_20 <div class="bg_primary p-y_2 text-left w_20 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_25 <div class="bg_primary p-y_2 text-left w_25 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_30 <div class="bg_primary p-y_2 text-left w_30 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_35 <div class="bg_primary p-y_2 text-left w_35 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_40 <div class="bg_primary p-y_2 text-left w_40 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_45 <div class="bg_primary p-y_2 text-left w_45 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_50 <div class="bg_primary p-y_2 text-left w_50 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_55 <div class="bg_primary p-y_2 text-left w_55 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_60 <div class="bg_primary p-y_2 text-left w_60 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_65 <div class="bg_primary p-y_2 text-left w_65 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_70 <div class="bg_primary p-y_2 text-left w_70 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_75 <div class="bg_primary p-y_2 text-left w_75 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_80 <div class="bg_primary p-y_2 text-left w_80 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_85 <div class="bg_primary p-y_2 text-left w_85 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_90 <div class="bg_primary p-y_2 text-left w_90 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_95 <div class="bg_primary p-y_2 text-left w_95 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_100 <div class="bg_primary p-y_2 text-left w_100 m-t_3">&nbsp;</div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_1-third <div class="bg_primary p-y_2 text-left w_1-third m-t_3"> &nbsp; </div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> w_2-thirds <div class="bg_primary p-y_2 text-left w_2-thirds m-t_3"> &nbsp; </div>
</div>

Weight: 111

Styleguide Layouts.Width

 */
@include loop-style(width, '', '!important');
@include loop-style(max-width, '', '!important');

@media #{$medium-up} {
	@include loop-style(width, $bp-label_medium, '!important');
	@include loop-style(max-width, $bp-label_medium, '!important');
}

@media #{$large-up} {
	@include loop-style(width, $bp-label_large, '!important');
	@include loop-style(max-width, $bp-label_large, '!important');
}

/*
Max Width

Control the max width by 10% increments or by readable which is 65em

#{class-name-constructor(max-width,10)}.lh_4 - width size for a column that would match up to the smallest breakpoint.
#{class-name-constructor(max-width,20)}.font_n1.lh_4 - comfortable reading range for font_n1 text.
#{class-name-constructor(max-width,50)}.font_0.lh_3 - comfortable reading range for font_0 text
#{class-name-constructor(max-width,60)}.font_1.lh_2 - comfortable reading range for font_1 text
#{class-name-constructor(max-width,80)}.lh_4 - large screen format of 1280px #{map-get($theme-palette,primary)}


Markup:
<div class=" br_solid br_transparent bg_alert-3 p_5">
	<div class="bg_white text-left {{ modifier_class }}">
		<strong>{{ modifier_class }}</strong> Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
		inceptos himenaeos. Nam nec ante. Sed lacinia, urna non tincidunt mattis, tortor neque adipiscing diam, a cursus
		ipsum ante quis turpis. Nulla facilisi. Ut fringilla. Suspendisse potenti. Nunc feugiat mi a tellus consequat
		imperdiet. Vestibulum sapien. Proin quam. Etiam ultrices. Suspendisse in justo eu magna luctus suscipit. Sed
		lectus. Integer euismod lacus luctus magna. Quisque cursus, metus vitae pharetra auctor, sem massa mattis sem,
		at interdum magna augue eget diam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
		cubilia Curae; Morbi lacinia molestie dui. Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue
		elementum. Morbi in ipsum sit amet pede facilisis laoreet. Donec lacus nunc, viverra nec.
	</div>
</div>

Styleguide Layouts.MaxWidth

 */


/*
Custom Max Width

Add "max-w_custom" class to the component that you want to set a custom width for. Then the custom width value can be passed to that component from either the parent component or itself using `style="--max-w_custom:rem value"`
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3" style="--max-w_custom:12rem"> Width value passed from parent component <div class="bg_primary p-y_2 text-left c_white m-t_3 max-w_custom"> 12rem </div>
</div>
<div class=" br_solid br_black-1 br_1 bg_highlight-5 p-y_3"> Width value set inline <div class="bg_primary p-y_2 text-left c_white m-t_3 max-w_custom" style="--max-w_custom:24rem"> 24rem </div>
</div>

Styleguide Layouts.Width.CustomMaxWidth

 */
.max-w_custom {
	max-width: var(--max-w_custom, 100%);
}