$texture-light: '../img/whisper.png';
$texture-medium: '../img/billie-holiday-speck.png';
$texture-dust: '../img/dust.png';
$texture-dark: '../img/cross_stripes.png';
$texture-disabled: '../img/cross_disabled.png';
$texture-cross: '../img/cross_color.png';
$texture-connectwork: '../img/connectwork.png';

%texture_base {
	background-attachment: local;
	background-repeat: repeat;
	background-position: top right;
	background-origin: border-box;
}

@mixin texture-light() {
	@extend %texture_base;
	background-image: url($texture-light);
}
@mixin texture-medium {
	@extend %texture_base;
	background-image: url($texture-medium);
}
@mixin texture-dust {
	@extend %texture_base;
	background-image: url($texture-dust);
}
@mixin texture-medium-shaded($level: 0) {
	@extend %texture_base;
	background-color: scale-color(map-get($theme-palette, shade), $lightness: 20% * $level);
	background-image: url($texture-medium);
}

@mixin texture-dark() {
	@extend %texture_base;
	background-image: url($texture-dark);
}

@mixin texture-disabled() {
	@extend %texture_base;
	background-image: url($texture-disabled);
}

@mixin texture-cross() {
	@extend %texture_base;
	background-image: url($texture-cross);
}
@mixin texture-dynamic() {
	@extend %texture_base;
	background-image: url($texture-connectwork);
}
