


	
/*
	Display

	<div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div> Alter the display of an object to create more complicated UI and Layouts. #{modifierClassTable(display , true)}  

	Styleguide Layouts.Display

 */

/*
	Display Nothing on Empty

	This utility class is used to hide an element when it contains no content. This is super useful for creating a space that needs to be decorated but also doesn't need to be shown when empty. 

	Markup:
	<h2> <strong class="font_bold c_primary-n2 uppercase">Does Not Have</strong> class display_none:empty and has <strong class="font_bold c_primary-n2 uppercase">No Content</strong></h2>
	<div class="p-b_3 br_solid br_primary br_1"></div>
	<h2> <strong class="font_bold c_primary-n2 uppercase">Has</strong> class display_none:empty and has <strong class="font_bold c_primary-n2 uppercase">No Content</strong></h2>
	<div class="p-b_3 br_solid br_primary br_1 display_none:empty"></div>
	<h2> <strong class="font_bold c_primary-n2 uppercase">Has</strong> class display_none:empty has <strong class="font_bold c_primary-n2 uppercase">Content</strong></h2>
	<div class="p-b_3 br_solid br_primary br_1 display_none:empty">any content including space will show the element</div>

	MarkupWrapperClasses: w_100

	NOTE: !! Whitespace counts as not empty !!

	Weight:10

	Styleguide Layouts.Display.Empty

 */
 /*
 
 Display For Touch Devices
 
 Display has extra breakpoints `:touch` that can be used to create layouts that are more friendly for touch screen inputs. Touch devices are often smaller than desktop devices and input method is a finger that can't hover.  This utility class  can be used to create a layout that is more friendly for touch devices. Touch layouts are often needed to uncover UI that only appears on Hover.
  
 Markup:
 <div class="p_3 font_bold">This element shows for both <strong class="font_bold c_primary-n2 uppercase"><i class="fas fa-desktop"></i> desktop</strong> and <strong class="font_bold c_primary-n2 uppercase"><i class="fas fa-hand-pointer"></i>Touch </strong> devices</div>
 <div class="p_3 font_bold display_none:touch">This element shows only for <strong class="font_bold c_primary-n2 uppercase"><i class="fas fa-desktop"></i> desktop</strong> devices</div>
 <div class="p_3 font_bold block:touch display_none">This element shows only for <strong class="font_bold c_primary-n2 uppercase"><i class="fas fa-hand-pointer"></i>Touch </strong> devices</div> 
 
 MarkupWrapperClasses: w_100
  
 Weight:0
  
 Styleguide Layouts.Display.ExtraBreakpoints
 
 */


@include loop-style(display);

@media #{$medium-up} {
	@include loop-style(display, $bp-label_medium);
}
@media #{$large-up} {
	@include loop-style(display, $bp-label_large);
}

@media #{$large-up} {
	@include loop-style(display, $bp-label_large);
}

@media #{$input_touch} {
	@include loop-style(display, $bp-label_touch);
}
.display_none#{$bp-label_empty}:empty {
	display: none;
}

/*
Z Index

Alter the Z Index of objects to help deal with objects that are out of normal z-index flow.  #{modifierClassTable(z-index)}  

.z_0   -  z-index: -1
.z_1   -  z-index: 1
.z_3   -  z-index: 1000
.z_5   -  z-index: 9999

Markup:
<div class="bg_alert-3 block br_solid br_transparent relative text_center" style=" height: 15rem; width: 100%;">
	<div class="absolute bg_black-5 c_white inline-block l_0 m_4 t_1 text_center w_70 z_3" style=" height: 13rem;">z-index of 1000</div>
	<div class="absolute bg_primary c_white inline-block m_5 r_0 t_1 text_center w_70 {{modifier_class}}" style=" height: 11rem;">{{modifier_class}}</div>
</div>

Styleguide Layouts.z-index

*/
@include loop-style(z-index);


.vertical-align_top {
	vertical-align: top;
}
.vertical-align_middle {
	vertical-align: middle;
}
.vertical-align_bottom {
	vertical-align: bottom;
}
.vertical-align_baseline {
	vertical-align: baseline;
}

/*
Isolation

Altering the isolation of an object to create more complicated UI and Layouts when dealing with z-index.  #{modifierClassTable(isolation)} 


Markup:
<div></div>

Styleguide Layouts.Isolation

*/

@include loop-style(isolation);

/*
	Vertical Align

	Quickly alter the vertical alignment of the child. caution the vertical only works on valid combinations. 

	.vertical-align_top              		- the child is aligned to the Top
	.vertical-align_middle              	- the child is aligned to the Middle
	.vertical-align_bottom            		- the child is aligned to the Bottom
	.vertical-align_baseline          		- the child is aligned to the Baseline

	Styleguide Layouts.Display.VerticalAlignment

 */

