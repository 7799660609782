/*
Aspect Ratio

Aspect Ratio lets you define the ratio of width to height of the container.  This is a great utility for images, videos, icons and layouts that need to conform to a ratio shape as it responds. It will set the object to 100% width of the container.  #{modifierClassTable(aspect-ratio)} 



Styleguide Layouts.AspectRatio

*/
[class *= 'aspect_']{
	width: 100%;
	overflow: hidden;
}
.aspect_leaderboard{
	max-width: 728px;
}
.aspect_billboard{
	max-width: 970px;
}
.aspect_bigbox{
	max-width: 300px;
}
.aspect_skyscraper{
	max-width: 160px;
}
@include loop-style(aspect-ratio);
@media #{$medium-up} {
	.aspect_leaderboard#{$bp-label_medium}{
		max-width: 728px;
	}
	.aspect_billboard#{$bp-label_medium}{
		max-width: 970px;
	}
	.aspect_bigbox#{$bp-label_medium}{
		max-width: 300px;
	}
	.aspect_skyscraper#{$bp-label_medium}{
		max-width: 160px;
	}
	@include loop-style(aspect-ratio, $bp-label_medium);
}
@media #{$large-up} {
	.aspect_leaderboard#{$bp-label_large}{
		max-width: 728px;
	}
	.aspect_billboard#{$bp-label_large}{
		max-width: 970px;
	}
	.aspect_bigbox#{$bp-label_large}{
		max-width: 300px;
	}
	.aspect_skyscraper#{$bp-label_large}{
		max-width: 160px;
	}
	@include loop-style(aspect-ratio, $bp-label_large);
}

