/*
Typography

These utility classes enable the manipulation of features such as font size, weight, decoration, white-space, and overflow, providing greater control over the typographic elements in your design.

Weight: -100

Styleguide Typography
*/
/*
Font

Weight: 0

Styleguide Typography.Font
*/
/*
Text

Weight: 1

Styleguide Typography.Text
*/

/* No Styleguide reference. */

$white: rgb(246, 246, 246) !default;
$black: rgb(19, 18, 18) !default;
.font_unset {
    font-style: unset;
    font-weight: unset;
    font-size: unset;
    text-decoration: unset;
    text-align: unset;
    text-transform: unset;
    white-space: unset;
}
/*
  Font Weight

These classes are examples of CSS styles that operate as utility classes to control font weight across various text elements.

.font_light   -   Light, 100
.font_regular   -   Regular, 300
.font_medium   -   Medium, 500
.font_bold   -   Bold, 700
.font_xbold   -   Extra Bold, 900

  Markup:
  <div class="{{modifier_class}} font_normal font_2 font_ui">
    <div class="lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>

Weight: -80

  Styleguide Typography.Font.Weight
 */

// @include loop-style(font-weight, '', '!important');
:root{
--font_light:300;
--font_regular:400;
--font_medium:500;
--font_bold:700;
--font_xbold:900;
}

.font_light{ font-weight: var(--font_light,300) }
.font_regular{font-weight: var(--font_regular,400)}
.font_medium{font-weight: var(--font_medium,500)}
.font_bold, strong, b{font-weight: var(--font_bold,700)}
.font_xbold{font-weight: var(--font_xbold,900)}

/*
  Font Family

You can modify the font family used in your text elements with these simple utility classe.

  .font_accent            -  Font Accent: Primarily used in main navigation elements and certain headers.
  .font_display         -  Font Display: Employed in headlines and buttons.      
  .font_copy          -  Font Copy: Typically used in content text.          
  .font_ui          -  Font UI: Applied to components like navigation elements and dense user interface elements.           
  .font_mono         - Font Mono: This is commonly used in quotations, data displays, or code snippets    

  Markup:
  <div class="{{modifier_class}} font_3">
    <div class="lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>

  Styleguide Typography.Font.Family
 */

@include loop-style(font-family);
// @include debug-map($fonts);
@each $key , $value in $fonts {
  $name: map-get($map: $value, $key: name );
  $stack: map-get($map: $value, $key: stack );
  $weights: map-get($map: $value, $key: weight );
 :root{  --font-family_#{$key}: #{$stack};}

  .font_#{$key}{
    font-family:#{$stack};
    font-family:var(--font-family_#{$key});
    @each $weight-name, $weight-value in $weights{
      --font_#{$weight-name}:#{$weight-value};
    }
  }
}

.reading-typography{
  @extend .font_regular;
  @extend .font_copy;
}
.reading-typography:where(h1,h2,h3,h4,h5,h6){
  @extend .font_display;
}
/*
  Font Style

  These utility classes allow you to adjust the style of your text:

  .font_normal            - Sets font style to normal
  .font_italic            - Sets font style to italic      

  Markup:
  <div class="{{modifier_class}} font_normal font_2">
    <div class="lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>

  Styleguide Typography.Font.Style
 */

.font_normal {
    font-style: normal !important;
}

.font_italic {
    font-style: italic !important;
}


/*
Font Color

Using these straightforward utility classes, you can adjust the color of your text.

.c_white - text that's white
.c_black -  text that's black
.c_primary -  text that's primary
.c_alert -  text that's alert
.c_info -  text that's info
.c_warning -  text that's warning the user
.c_inherit - text that inherits from its parent

Markup:
<div class="{{modifier_class}} font_normal font_2 font_bold bg_shade-4 p_3">
  <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
</div>

Weight: -90

Styleguide Typography.Font.Color
 */

@each $color-name,
$hex-value in $theme-palette {
    @include color-loop(color, $color-name, $hex-value);
}


/*
Font Color: Black & White

black and white fonts can be modified to have levels of transparency between 0-9.

.c_white - text that's white
.c_black -  text that's black


Markup:
<div class="font_normal font_2 bg_primary font_ui">
  <div class="{{modifier_class}} p_3">
    <div class="font_3 font_bold">{{modifier_class}} </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
    <div class="{{modifier_class}}-0 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-0 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
    <div class="{{modifier_class}}-1 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-1 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
    <div class="{{modifier_class}}-2 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-2 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
    <div class="{{modifier_class}}-3 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-3 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
    <div class="{{modifier_class}}-4 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-4 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
    <div class="{{modifier_class}}-5 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-5 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
    <div class="{{modifier_class}}-9 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-6 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
      <div class="{{modifier_class}}-7 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-7 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
      <div class="{{modifier_class}}-8 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-8 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
      <div class="{{modifier_class}}-9 p_3">
    <div class="font_3 font_bold">{{modifier_class}}-9 </div>
    <div class="text-lowercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="text-uppercase">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>
</div>

NOTE: It's important to note that, unlike other text colors, these colors are transparent. If you're aiming for grey, opaque text, you should use the .shade_(n) utility class.

Weight: -91


Styleguide Typography.Font.Color.Black&White
 */

.c_white {
    color: $white !important;
}

.c_black {
    color: $black !important;
}

@include loop-transparent(color-transparent, 'black', '', '!important', false);
@include loop-transparent(color-transparent, 'white', '', '!important', false);
.c_inherit {
  color: inherit;
}

.h\:c_inherit:hover {
  color: inherit;
}

.c_inherit-all {
  &:hover,&:active,&:visited,&:focus,&{
    color: inherit;
  }
}




/*
  Font Size

    <div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div>You can alter the size of the font with font_[x] test 1
    #{ modifierClassTable(font-size) }
    
  .font_10 - 10x
  .font_9 - 9x
  .font_8 - 8x
  .font_7 - 7x
  .font_6 - 6x
  .font_5 - Largest
  .font_4 - Large
  .font_3 - Big
  .font_2 - Big-ish
  .font_1 - Slightly Bigger
  .font_0 - Default Size
  .font_n1 - Shrunk
  .font_n2 - small
  .font_n3 - smaller
  .font_n4 - even smaller
  .font_n5 - smallest

  Markup:
  <div class="{{modifier_class}} font_normal">
    <div class="lowercase pre-wrap">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div class="uppercase pre-wrap">a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
  </div>

  Weight: -100

  Styleguide Typography.Font.Size
 */

@include loop-style(font-size);
@media #{$medium-up} {
    @include loop-style(font-size, $bp-label_medium, '');
}

@media #{$large-up} {
    @include loop-style(font-size, $bp-label_large, '');
}

@media #{$xlarge-up} {
    @include loop-style(font-size, $bp-label_xlarge, '');
}

.font-size_up {
    font-size: calc(1em * #{$ms-ratio});
}

.font-size_down {
    font-size: calc(1em /#{$ms-ratio});
}
.font-size_up-1 {
  font-size: calc(1em * #{$ms-ratio}* #{$ms-ratio});
}

.font-size_down-1 {
  font-size: calc(1em / #{$ms-ratio} / #{$ms-ratio});
}
.font-size_up-2 {
  font-size: calc(1em * #{$ms-ratio} * #{$ms-ratio}  * #{$ms-ratio});
}

.font-size_down-2 {
  font-size: calc(1em / #{$ms-ratio} / #{$ms-ratio} / #{$ms-ratio});
}

/*
  Text Wrap

  <div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div> You can alter the wrapping style with these UCs.

  .wrap           - alter the wrapping style to wrap
  .pre-wrap       - alter the wrapping style to pre-wrap    
  .nowrap         - alter the wrapping style to nowrap  

  Markup:
  <div class="{{modifier_class}} font_normal max-w_30">
    Quae sapiente reprehenderit explicabo esse consequatur error fugiat. Voluptas odit ipsam architecto quae perspiciatis nostrum autem at. Ut veritatis molestiae distinctio autem repudiandae voluptatem voluptatibus aspernatur.
  </div>

  Styleguide Typography.Text.Wrap
 */

.wrap {
    white-space: normal;
}

.pre-wrap {
    white-space: pre-wrap;
    letter-spacing: -0.01em;
}

.nowrap {
    white-space: nowrap;
}

@media #{$medium-up} {
    .wrap\:md {
        white-space: normal;
    }
    .pre-wrap\:md {
        white-space: pre-wrap;
        letter-spacing: -0.01em;
    }
    .nowrap\:md {
        white-space: nowrap;
    }
}

@media #{$large-up} {
    .wrap\:lg {
        white-space: normal;
    }
    .pre-wrap\:lg {
        white-space: pre-wrap;
        letter-spacing: -0.01em;
    }
    .nowrap\:lg {
        white-space: nowrap;
    }
}

@include loop-style(word-break);
@media #{$large-up} {
    @include loop-style(word-break);
}

@media #{$large-up} {
    @include loop-style(word-break);
}


/*
	Text Decoration

	Decoration can be easily added to text through these UCs.  

  .underline			        - solid underline the text
  .dotted			            - dotted underline the text
  .double                 - double underline the text
  .dashed                 - dashed underline the text
  .undecorated            - unset the decoration of the text     
  .uppercase              - uppercase the text    
  .lowercase              - lowercase the text
  .capitalize             - capitalize the text

	Markup:
	<div class="{{modifier_class}} p_3 br_solid font_bold br_black-1 br_1 bg bg_primary c_white">text has a decoration of {{modifier_class}} <a>I am a link also decorated</a></div>

	Styleguide Typography.Text.Decoration

 */

.lowercase {
    text-transform: lowercase;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.underline {
    text-decoration: underline;
}

.dotted {
    text-decoration: dotted;
}

.double {
    text-decoration: double;
}

.dashed {
    text-decoration: dashed;
}

.undecorated {
    text-decoration: none;
}


/*
	Text Shadows

	Shadows can be easily added to text there is limited shadows black and white and negative black and white  

	.text-shadow_white-n1			- inset white shadow
	.text-shadow_white-1			- white shadow
	.text-shadow_black-n1			- inset black shadow
	.text-shadow_black-1			- black shadow
	.text-shadow_none			- no shadow


	Markup:
	<div class="{{modifier_class}} p_3 br_solid font_bold br_black-1 br_1 bg bg_primary c_white">text has shadow of {{modifier_class}}</div>

	Styleguide Typography.Text.Shadow

 */

@include loop-style(text-shadow);

/*
Text Indent

Indent text needs to have a modifier to like `text-indent_2

text-indent_n3       - text-indent of  negative 3 rem
text-indent_n2       - text-indent of  negative 2 rem
text-indent_n1       - text-indent of  negative 1 rem
text-indent_0       - text-indent  of   positive 0 rem
text-indent_1       - text-indent  of   positive 1 rem
text-indent_2       - text-indent  of   positive 2 rem
text-indent_3       - text-indent  of   positive 3 rem

Markup:
<div class="font_normal font_1 m-x_5 br_solid br-l_1 br_black-4">
<p class="">text that has no indent/p>
<p class="{{modifier_class}}">text that has an indent magnitude of {{modifier_class}} </p>
</div>

Styleguide Typography.Text.Indent

 */

@include loop-style(text-indent);

/*
  Line Height

  Change the line height of text with `lh_x`.

  .lh_0             -  0
  .lh_1             -  1           
  .lh_2             -  1.25  
  .lh_3             -  1.45  
  .lh_4             -  1.75     
  .lh_5             -  2.5     

  Markup:
  <div class="font_normal font_1 p_3">
    <h3 class="font_2 font_bold c_shade_3 p-b_3">{{modifier_class}}</h3>
    <p class="{{modifier_class}}">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
  </div>

  Styleguide Typography.Text.LineHeight
 */

@include loop-style(line-height, '', '!important');

/*
  Alignment

  Change the alignment of text.

  .text_right             -  right alignment
  .text_center             -  center alignment           
  .text_left             -  left alignment      

  Markup:
  <div class="font_normal font_1 p_3">
    <h3 class="font_2 font_bold c_shade_3 p-b_3 {{modifier_class}}">{{modifier_class}}</h3>
    <p class="{{modifier_class}} lh_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
  </div>

  Styleguide Typography.Text.Alignment
 */

@include loop-style(text-align, '', '!important');
@media #{$medium-up} {
    @include loop-style(text-align, $bp-label_medium, '!important');
}

@media #{$large-up} {
    @include loop-style(text-align, $bp-label_large, '!important');
}


