/*
Gradient
 
Simple Gradients that are either linear or radial. These gradients can be modified by passing in the css properties to change the start and end color and the direction the gradient is rendered. `style="--start-color:hexcolor;--end-color:hexcolor;--origin:top right"`<table><thead><tr><th>css property</th><th>definition</th><th>acceptable options</th></tr></thead><tbody><tr><td>--start-color</td><td>the starting color of the gradient or center color for radial</td><td>proper formatted color: hex, hsl, hsla, rgb, rgba</td></tr><tr><td>--end-color</td><td>the ending color or the outside color when radial</td><td>proper formatted color: hex, hsl, hsla, rgb, rgba</td></tr><tr><td>--origin</td><td>point in the container where the gradients starts</td><td>these words depend on the type of gradient</td></tr></tbody></table> 
 
Weight: 121
 
Styleguide Decorator.Background.Gradient
*/
:root{
	$start:get-theme-color(primary, -1,CSSVAR);
	$end:get-theme-color(acc, -1,CSSVAR);
	--start-color: #{$start};
	--end-color: #{$end};
	--origin:at top left;
	
}
.radial-gradient_custom{
	--origin:at top left;
	background-color:var(--start-color);
	background-image:radial-gradient(var(--origin),var(--start-color), var(--end-color));
}
.linear-gradient_custom{
	--origin:to top left;
	background-color:var(--start-color);
	background-image:linear-gradient(var(--origin),var(--start-color), var(--end-color));
} 


/*
Linear Gradient
 
The default gradient is the ACC Blue to the Primary Color of the Brand. Pass in css properties to manipulate the colors you want to use. <a class="link" href="https://developer.mozilla.org/en-US/docs/Web/CSS/gradient/linear-gradient" target="_blank">linear gradient css documentation</a>
 

<div class="linear-gradient_custom w_100 aspect_21x9 max-w_40 m_4 c_white grid items_center justify_center">Default Coloring</div>
<div class="linear-gradient_custom w_100 aspect_21x9 max-w_40 m_4 c_white grid items_center justify_center" style="--start-color:#b3318e;--end-color:#450c5a;--origin:to top right">Modified Coloring</div>

MarkupWrapperClasses:
flex flex_row bg_shade-5 p_2 texture_light

Weight: 1

Styleguide Decorator.Background.Gradient.linear
*/

/*
Radial Gradient
 
The default gradient is the ACC Blue to the Primary Color of the Brand. Pass in css properties to manipulate the colors you want to use.  <a class="link" href="https://developer.mozilla.org/en-US/docs/Web/CSS/gradient/radial-gradient" target="_blank">radial gradient css documentation</a>
 

<div class="radial-gradient_custom w_100 aspect_21x9 max-w_40 m_4 c_white grid items_center justify_center">Default Coloring</div>
<div class="radial-gradient_custom w_100 aspect_21x9 max-w_40 m_4 c_white grid items_center justify_center" style="--start-color:#b3318e;--end-color:#450c5a;--origin:at top">Modified Coloring</div>

MarkupWrapperClasses:
flex flex_row bg_shade-5 p_2 texture_light

Weight: 2

Styleguide Decorator.Background.Gradient.Radial
*/