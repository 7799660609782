/*
Background 

Background of containers can be easily maniputlated with textures, colors, and shadows.  

Weight: 120

Styleguide Decorator.Background
*/
/* No styleguide reference. */
/*
  Background Colors
 
  Background colors can be easily change with bg_`color-name`  
 
  bg_primary              - background using primary color
  bg_secondary            - background using secondary color
  bg_shade                - background using shade color
  bg_alert                - background using alert color
  bg_warning              - background using warning color
  bg_success              - background using success color
  bg_info                 - background using info color
  bg_highlight            - background using highlight color  

  Weight: -100

  Markup:
  <div class="{{modifier_class}} p_3 c_white">Background Color of {{modifier_class}}</div>
 
  MarkupWrapperClasses:
  grid template-x_30

  Styleguide Decorator.Background.Color
 
 */
/*
  Background Color Tint

  <div>Background colors can be shifted by appending a modifier of n5 through 5.  The negative(n) numbers mix the color with black and the positive numbers mix the color with white.<table><thead><tr><th>Classes</th><th>Effect</th></tr></thead><tbody><tr><td>.bg_(color)-0, .bg_(color)</td><td>pure color</td></tr><tr><td>.bg_(color)-1</td><td>color with 15% white mixed in</td></tr><tr><td>.bg_(color)-2</td><td>color with 37% white mixed in</td></tr><tr><td>.bg_(color)-3</td><td>color with 60% white mixed in</td></tr><tr><td>.bg_(color)-4</td><td>color with 78% white mixed in</td></tr><tr><td>.bg_(color)-5</td><td>color with 90% white mixed in</td></tr><tr><td>.bg_(color)-n1</td><td>color with 10% black mixed in</td></tr><tr><td>.bg_(color)-n2</td><td>color with 25% black mixed in</td></tr><tr><td>.bg_(color)-n3</td><td>color with 45% black mixed in</td></tr><tr><td>.bg_(color)-n4</td><td>color with 58% black mixed in</td></tr><tr><td>.bg_(color)-n5</td><td>color with 75% black mixed in</td></tr></tbody></table></div>  
 
  bg_primary              - background using primary color
  bg_secondary            - background using secondary color
  bg_shade                - background using shade color
  bg_alert                - background using alert color
  bg_warning              - background using warning color
  bg_success              - background using success color
  bg_info                 - background using info color
  bg_highlight            - background using highlight color  

    Weight: -50

  Markup:
  <div class="bg_white flex_auto text_center p_2 p-y_5 ">white</div>
  <div class="{{modifier_class}}-5 flex_auto text_center  p_2 p-y_5">5</div>
  <div class="{{modifier_class}}-4 flex_auto text_center  p_2 p-y_5">4</div>
  <div class="{{modifier_class}}-3 flex_auto text_center  p_2 p-y_5">3</div>
  <div class="{{modifier_class}}-2 flex_auto text_center  p_2 p-y_5">2</div>
  <div class="{{modifier_class}}-1 flex_auto text_center  c_white p_2 p-y_5">1</div>
  <div class="{{modifier_class}}-0 flex_auto text_center  c_white p_2 p-y_5">0</div>
  <div class="{{modifier_class}}-n1 flex_auto text_center  c_white p_2 p-y_5">n1</div>
  <div class="{{modifier_class}}-n2 flex_auto text_center  c_white p_2 p-y_5">n2</div>
  <div class="{{modifier_class}}-n3 flex_auto text_center  c_white p_2 p-y_5">n3</div>
  <div class="{{modifier_class}}-n4 flex_auto text_center  c_white p_2 p-y_5">n4</div>
  <div class="{{modifier_class}}-n5 flex_auto text_center  c_white p_2 p-y_5">n5</div>
  <div class="bg_black flex_auto text_center  c_white p_2 p-y_5">black</div>


  MarkupWrapperClasses:
  flex flex_row bg_shade-5 p_2 texture_light

  Styleguide Decorator.Background.Color.Shades
 
 */
.bg_transparent {
	background-color: transparent !important;
}
@media #{$medium-up} {
	.bg_transparent {
		background-color: transparent !important;
	}
}
@media #{$large-up} {
	.bg_transparent {
		background-color: transparent !important;
	}
}
@each $color-name, $color in $theme-palette {
	@include color-loop(background-color, $color-name, $color);
}

#{ class-name-constructor(background-color, white)} {
	background-color: $white;
}
#{ class-name-constructor(background-color, black)} {
	background-color: $black;
}
@include loop-transparent(background-transparent, black, '', '!important', false);
@include loop-transparent(background-transparent, white, '', '!important', false);
/*
  Background Colors Alpha
 
  Background colors of white and black can be shifted by appending a modifier bg_{{color-name}}-{{0 to 9}} to shift its transparency
 
  black              - background color of black modified with alphas
  white              - background color of white modified with alphas

  Markup:
  <div class="bg_{{modifier_class}}-0 flex_auto text_center c_white  p_2 p-y_5">{{modifier_class}}-0</div>
  <div class="bg_{{modifier_class}}-1 flex_auto text_center c_white  p_2 p-y_5">{{modifier_class}}-1</div>
  <div class="bg_{{modifier_class}}-2 flex_auto text_center c_white  p_2 p-y_5">{{modifier_class}}-2</div>
  <div class="bg_{{modifier_class}}-3 flex_auto text_center c_white  p_2 p-y_5">{{modifier_class}}-3</div>
  <div class="bg_{{modifier_class}}-4 flex_auto text_center c_white p_2 p-y_5">{{modifier_class}}-4</div>
  <div class="bg_{{modifier_class}}-5 flex_auto text_center c_white p_2 p-y_5">{{modifier_class}}-5</div>
  <div class="bg_{{modifier_class}}-6 flex_auto text_center c_white p_2 p-y_5">{{modifier_class}}-6</div>
  <div class="bg_{{modifier_class}}-7 flex_auto text_center c_white p_2 p-y_5">{{modifier_class}}-7</div>
  <div class="bg_{{modifier_class}}-8 flex_auto text_center c_white p_2 p-y_5">{{modifier_class}}-8</div>
  <div class="bg_{{modifier_class}}-9 flex_auto text_center c_white p_2 p-y_5">{{modifier_class}}-9</div>
  <div class="bg_{{modifier_class}} flex_auto text_center c_white p_2 p-y_5">{{modifier_class}}</div>


  MarkupWrapperClasses:
  flex p_4 bg_primary

  Styleguide Decorator.Background.Color.With Alphas
 
 */

/*
Background Position

For many images you will need top place background images in to a cardnial location.

.bg_left	            - cardnial left
.bg_top	            - cardnial top
.bg_right	          - cardnial right
.bg_bottom           - cardnial bottom
.bg_center           - cardnial center center

Markup:
<div class="{{modifier_class}} seal_tiny" style="height:200px;max-width: unset;background-size: 100px;"><span class="alt">{{modifier_class}}</span></div>

MarkupWrapperClasses:
bg_shade-2 max-w_40 br_solid br_primary br_1

Styleguide Decorator.Background.Image Positon

*/

@include loop-style(background-position, '', '!important');
/*
Background Size

For many images you will need top place background images in to a cardnial location.

.bg_unset	            - removes any css background size 
.bg_auto           - auto
.bg_cover	            - cover
.bg_contain	          - contain


Markup:
<div class="{{modifier_class}} seal_tiny" style="height:200px;max-width: unset;"><span class="alt">{{modifier_class}}</span></div>

MarkupWrapperClasses:
bg_shade-2 max-w_40 br_solid br_primary br_1

Styleguide Decorator.Background.Size

*/
.bg_unset {
	background-size: unset;
  object-fit: unset;
}
.bg_auto {
	background-size: auto;
  object-fit: none;
}
.bg_cover {
	background-size: cover;
  object-fit: cover;
}
.bg_contain {
	background-size: contain;
  object-fit: contain;
}

/*
Background Repeat

repeating a background image is easy.

.bg_norepeat	            - norepeat 
.bg_repeat                - repeat
.bg_repeat-x              - repoeat-x
.bg_repeat-y              - repeat-y
.bg_repeat-space          - repeat-space

Markup:
<div class="{{modifier_class}} seal_tiny bg_auto" style="height:200px;max-width: unset;background-size: 100px;"><span class="alt">{{modifier_class}}</span></div>

MarkupWrapperClasses:
bg_shade-2 max-w_40 br_solid br_primary br_1



Styleguide Decorator.Background.Image Repeat

*/

.bg_no-repeat {
	background-repeat: no-repeat;
}
.bg_repeat {
	background-repeat: repeat;
}
.bg_repeat-x {
	background-repeat: repeat-x;
}
.bg_repeat-y {
	background-repeat: repeat-y;
}
.bg_repeat-space {
	background-repeat: space;
}
