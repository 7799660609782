


/*
Margin

<div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div>Margin classes are mobile first with postfix modifier for `:md`and `:lg` variations for responsive sizing. Margins that use the short codes of all margin, auto, x margins, and y margin are override by specific side margins. 			

m_0     	 		-  margin: 0 
m_n5 	 			-  margin:	 -2rem
m_n2 	 			-  margin:	 -0.25rem
m_4  	 			-  margin: 1rem
m-l_n5       	-  margin-left:	 -2rem
m-l_1        	-  margin-left: 1px
m-l_2        	-  margin-left: 0.25rem
m-l_3        	-  margin-left: 0.5rem
m-x_auto     	-  margin-left, margin-right: auto
m-y_3   	 		-  margin-top, margin-bottom: 0.5rem

Markup:
<div class="bg_warning-3 d-block br_1 br_solid">
	<div class="{{modifier_class}} p_2 bg_primary text_center c_white cell auto opacity_5 d-block max-w_30">Object  With Margins Inside Container</div>
</div>

Styleguide Layouts.Margin

 */

@include loop-style(margin, '', ' !important');
@include loop-style(margin-vertical, '', ' !important');
@include loop-style(margin-horizontal, '', ' !important');
@include loop-style(margin-top, '', ' !important');
@include loop-style(margin-bottom, '', ' !important');
@include loop-style(margin-left, '', ' !important');
@include loop-style(margin-right, '', ' !important');

@media #{$medium-up} {
	@include loop-style(margin, $bp-label_medium, ' !important');
	@include loop-style(margin-vertical, $bp-label_medium, ' !important');
	@include loop-style(margin-horizontal, $bp-label_medium, ' !important');
	@include loop-style(margin-top, $bp-label_medium, ' !important');
	@include loop-style(margin-bottom, $bp-label_medium, ' !important');
	@include loop-style(margin-left, $bp-label_medium, ' !important');
	@include loop-style(margin-right, $bp-label_medium, ' !important');
}

@media #{$large-up} {
	@include loop-style(margin, $bp-label_large, ' !important');
	@include loop-style(margin-vertical, $bp-label_large, ' !important');
	@include loop-style(margin-horizontal, $bp-label_large, ' !important');
	@include loop-style(margin-top, $bp-label_large, ' !important');
	@include loop-style(margin-bottom, $bp-label_large, ' !important');
	@include loop-style(margin-left, $bp-label_large, ' !important');
	@include loop-style(margin-right, $bp-label_large, ' !important');
}

// @debug modifierClassList(padding, "");
/*
Padding

<div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div>Quickly alter the padding of an object. #{modifierClassTable(	padding , true )}

.p_0      	-  padding: 0 
.p_1   				-  padding: 1px
.p_2   -  padding: 0.25rem
.p_3   -  padding: 0.5rem
.p_4   -  padding: 1rem
.p_5   -  padding: 2rem
.p-x_5             	 - left and right paddings set to 2x of global padding 
.p-y_5             	 - top and bottom paddings set to 2x of global padding 
.p-t_4          	- Top padding set to global padding
.p-r_4        	- Right padding set to global padding
.p-l_4        	 - Left padding set to global padding
.p-b_4      	 - Bottom padding set to global padding

Markup:
<div class="{{modifier_class}} br_solid br_transparent bg_alert-3  text_center">
	<div class="bg_primary-5 text_center">Content With Padding</div>
</div>

Styleguide Layouts.Padding

 */

@include loop-style(padding, '', ' !important');
@include loop-style(padding-vertical, '', ' !important');
@include loop-style(padding-horizontal, '', ' !important');
@include loop-style(padding-top, '', ' !important');
@include loop-style(padding-bottom, '', ' !important');
@include loop-style(padding-left, '', ' !important');
@include loop-style(padding-right, '', ' !important');

@media #{$medium-up} {
	@include loop-style(padding, $bp-label_medium, ' !important');
	@include loop-style(padding-vertical, $bp-label_medium, ' !important');
	@include loop-style(padding-horizontal, $bp-label_medium, ' !important');
	@include loop-style(padding-top, $bp-label_medium, ' !important');
	@include loop-style(padding-bottom, $bp-label_medium, ' !important');
	@include loop-style(padding-left, $bp-label_medium, ' !important');
	@include loop-style(padding-right, $bp-label_medium, ' !important');
}

@media #{$large-up} {
	@include loop-style(padding, $bp-label_large, ' !important');
	@include loop-style(padding-vertical, $bp-label_large, ' !important');
	@include loop-style(padding-horizontal, $bp-label_large, ' !important');
	@include loop-style(padding-top, $bp-label_large, ' !important');
	@include loop-style(padding-bottom, $bp-label_large, ' !important');
	@include loop-style(padding-left, $bp-label_large, ' !important');
	@include loop-style(padding-right, $bp-label_large, ' !important');
}

@media #{$input-touch} {
	@include loop-style(padding, $bp-label_touch, ' !important');
	@include loop-style(padding-vertical, $bp-label_touch, ' !important');
	@include loop-style(padding-horizontal, $bp-label_touch, ' !important');
	@include loop-style(padding-top, $bp-label_touch, ' !important');
	@include loop-style(padding-bottom, $bp-label_touch, ' !important');
	@include loop-style(padding-left, $bp-label_touch, ' !important');
	@include loop-style(padding-right, $bp-label_touch, ' !important');
}
// @debug modifierClassList(position, "");
/*
Position Type

<div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div>Quickly alter the position type and location of an object with 'absolute' and 'top'

.initial        	 -  position: initial 
.inherit       -  position: inherit
.relative      -  position: relative
.absolute      -  position: absolute
.fixed         -  position: fixed
.sticky        -  position: sticky
.unset         -  position: unset

Markup:
<div class="br_solid br_transparent bg_accent-3 relative p_5" style="height:200px;">
	<div class="bg_primary-5 text_center {{modifier_class}}">Position {{modifier_class}}</div>
</div>

Styleguide Layouts.Position

 */

@include loop-style(position, '', ' !important');

@media #{$medium-up} {
	@include loop-style(position, $bp-label_medium, ' !important');
}

@media #{$large-up} {
	@include loop-style(position, $bp-label_large, ' !important');
}

/*
Location Position


Directional Position

The directional positioning classes are used to specify the positioning of an element in a specific direction. The directional positioning classes are most useful when combined with the "absolute" positioning mode, but it can also be used with other positioning modes. It allows you to specify the positioning of an element in a specific direction, such as inset, top, right, bottom, or left.


Styleguide Layouts.Location


*/



/*
Inset


<div><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div>The "inset" CSS property allows you to specify the positioning of an element's four edges within its containing element. It provides a convenient shorthand for defining the values of "top," "right," "bottom," and "left" properties simultaneously. By using the "inset" property, you can easily control the placement of an element without the need to specify each edge individually.

#{class-name-constructor(inset,auto)} - modify inset of an absolute positioned block 
#{class-name-constructor(inset,0)} - modify inset of an absolute positioned block 
#{class-name-constructor(inset,3)} - modify inset of an absolute positioned block
#{class-name-constructor(inset,5)} - modify inset of an absolute positioned block
#{class-name-constructor(inset,n3)} - modify inset of an absolute positioned block
#{class-name-constructor(inset,n5)} - modify inset of an absolute positioned block


Markup:
<div class=" br_solid br_black br_1 bg_accent-3 relative p_5" style="height:200px;">
	<div class="bg_white-5 m_auto text_center absolute {{modifier_class}} opacity_5 br_solid br_black-5 br_1">Position Absolute {{modifier_class}}</div>
</div>

NOTE: The ".inset_x" CSS class has lower specificity in the cascade, enabling a corresponding directional class to override its positioning at that specific responsive layer.

Styleguide Layouts.Location.Inset

 */



/*
Directional Position

<div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div>Quickly alter the position type and location of an object with top, bottom, left, and right position modifiers. All of these modifiers will follow the rules of the containers position type. All of the examples below are using absolute positions to have the greatest effect. 

#{class-name-constructor(top,1)} - modify top of an absolute positioned block 
#{class-name-constructor(top,5)} - modify top of an absolute positioned block
#{class-name-constructor(bottom,1)} - modify bottom of an absolute positioned block 
#{class-name-constructor(bottom,5)} - modify bottom of an absolute positioned block
#{class-name-constructor(right,5)} - modify left of an absolute positioned block 
#{class-name-constructor(left,5)} - modify right of an absolute positioned block 


Markup:
<div class=" br_solid br_transparent bg_accent-3 relative p_5" style="height:200px;">
	<div class="bg_primary-5 text_center absolute {{modifier_class}}">Position Absolute {{modifier_class}}</div>
</div>

Styleguide Layouts.Location.Position

 */

 
@include loop-style(inset, '', '');
@include loop-style(top, '', '');
@include loop-style(bottom, '', '');
@include loop-style(left, '', '');
@include loop-style(right, '', '');

@media #{$medium-up} {
	@include loop-style(inset, $bp-label_medium, '');

	@include loop-style(top, $bp-label_medium, '');
	@include loop-style(bottom, $bp-label_medium, '');
	@include loop-style(left, $bp-label_medium, '');
	@include loop-style(right, $bp-label_medium, '');
}

@media #{$large-up} {
	@include loop-style(inset, $bp-label_large, '');

	@include loop-style(top, $bp-label_large, '');
	@include loop-style(bottom, $bp-label_large, '');
	@include loop-style(left, $bp-label_large, '');
	@include loop-style(right, $bp-label_large, '');
}
// @debug modifierClassList(float, "");
/*
Float

<div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div> Easily Add Float to an object

.float_unset     -  float: unset 
.float_none    -  float: none
.float_left    -  float: left
.float_right   -  float: right


Markup:
<div class=" br_solid br_transparent bg_accent-3 relative p_5" style="height:200px;">
	<div class="bg_primary-5 text_center {{modifier_class}}">Position Absolute {{modifier_class}}</div>
</div>

Styleguide Layouts.Float

 */
/*
Clear

<div class="p-b_3"><span class="bg_accent br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Is Responsive: breakpoint modifiers</span></div>Quickly alter the position type and location of an object with 'absolute' and 'top'

clear_fix  			- unset positioned block 
clear_left  		- inital positioned block 
clear_right  		- inherit positioned block 
clear_both  		- relative positioned block 


Styleguide Layouts.Clear

 */
.clear_fix:after {
	content: '';
	clear: both;
	display: table;
}

.clear_both {
	clear: both;
}
.clear_left {
	clear: left;
}
.clear_right {
	clear: right;
}
@include loop-style(float, '', '');
@media #{$medium-up} {
	@include loop-style(float, $bp-label_medium, '');
	.clear_both\:md {
		clear: both;
	}
	.clear_left\:md {
		clear: left;
	}
	.clear_right\:md {
		clear: right;
	}
}

@media #{$large-up} {
	@include loop-style(float, $bp-label_large, '');
	.clear_both\:lg {
		clear: both;
	}
	.clear_left\:lg {
		clear: left;
	}
	.clear_right\:lg {
		clear: right;
	}
}
