@use "sass:math";
@use "sass:list";
$debug-trace: true !default;


@function calcSaturationCSSvar($color,$modifier) {
    // @return var(--#{$color}-s);
    @if (type-of($modifier) == number and $modifier >= 0) {
        $mod: map-deep-get($value-to-unit, percent-positive, $modifier);
        $propertyValueModifier: math.div( $mod, 200%);
        @return calc( var( --#{$color}-s ) - ( var( --#{$color}-s ) * #{ $propertyValueModifier } ) );
    } @else {
        @if(type-of($modifier) == number){ $modifier: "n" + ($modifier * -1); }
        // $propertyValueModifier: math.div( #{map-deep-get($value-to-unit, percent-negative, $modifier)} , 100&);
        $mod: map-deep-get($value-to-unit, percent-negative, $modifier);
        $propertyValueModifier:math.div($mod,1000%);
		@return calc(  var(--#{$color}-s) +
            ((100% - var(--#{$color}-s)) * #{$propertyValueModifier})
            );
	}
}
@function calcLightnessCSSvar($color,$modifier) {
    @if (type-of($modifier) == number and $modifier >= 0) {
        $propertyValueModifier: math.div(map-get( map-get($value-to-unit, percent-positive), $modifier),100%);
		@return calc(
            var(--#{$color}-l) +
            ((100% - var(--#{$color}-l)) * #{$propertyValueModifier})
            );
    } @else {
        @if(type-of($modifier) == number){ $modifier: "n" + ($modifier * -1); }
        $propertyValueModifier: math.div(map-get( map-get($value-to-unit, percent-negative), $modifier),100%);
		@return calc( var( --#{$color}-l ) - ( var( --#{$color}-l ) * #{ $propertyValueModifier } ) );
	}
}
@function get-theme-color-CSSVAR($key: primary, $modifier: 0, $color-profile: hsla, $alpha:1){
    @if( $color-profile == hue ){ @return var(--#{$key}-h);}
    @if( $color-profile == saturation ){ @return calcSaturationCSSvar($key,$modifier);}
    @if( $color-profile == lightness ){ @return calcLightnessCSSvar($key,$modifier);}
    @return hsla( var(--#{$key}-h),
    calcSaturationCSSvar($key,$modifier),
    calcLightnessCSSvar($key,$modifier),
    $alpha                           
    );  
}
@function get-theme-color($key: primary, $modifier: 0, $color-profile: HEX, $alpha:1 , $theme-map: $theme-palette ) {
    $c: map-get($theme-map, $key);
    $modifierIsPositive: type-of($modifier) == number and $modifier > 0;
    @if $debug-trace {
        @debug 'Get Theme Color #{$key} modified by #{$modifier} with alpha #{$alpha} returning the color as #{$color-profile}';
    }
    @if( $color-profile == CSSVAR ){
        @if( $modifier == 0 ){
            @if($alpha==1){ 
                @return var(--#{$key});
                }
                @else{
                    @return hsla(var(--#{$key}-h),var(--#{$key}-s),var(--#{$key}-l), $alpha);
                }   
            }
        @return hsla( var(--#{$key}-h),
        calcSaturationCSSvar($key,$modifier),
		calcLightnessCSSvar($key,$modifier),
        $alpha                           
        );
    }
    @else{
        $mod: 0%;
        @if $modifier == 0 {
            @return colorMode( map-get($theme-map, $key), $color-profile);
        }
        @else if($color-profile == HSL){
            $h:hue($c);
            $s:0%;
            $l:0%;

            $unitlessLightness:math.div(lightness($c),1%);
            $unitlessSaturation:math.div(saturation($c),1%);
            @if ( $modifierIsPositive ){ 
                $modifierKey:$modifier;
                $mod: map-deep-get($value-to-unit, percent-positive, $modifierKey);
                $l: $unitlessLightness + ( (100 - $unitlessLightness) * math.div($mod,100%) ) * 1%;
                $s:$unitlessSaturation - ( $unitlessSaturation * math.div($mod,200%) ) * 1%;
            }
            @else{
                $modifierKey:$modifier;
                @if(type-of($modifier) == number){ $modifierKey: "n" + ( $modifier * -1); }
                $mod: map-deep-get($value-to-unit, percent-negative, $modifierKey);               
                $l: $unitlessLightness - ( $unitlessLightness * math.div($mod,100%) ) * 1%;
                $s:  $unitlessSaturation + ( (100 - $unitlessSaturation) * math.div($mod, 1000%) ) * 1%;
            }
            

            @return $h+' ,'+ $s+', '+ $l;
        }
        @else {
            $mod: 0%;
            $mix: map-get($value-to-unit, percent-positive);
            @if ($modifierIsPositive == false ){
                @if(type-of($modifier) == number){ $modifier: "n" + ($modifier * -1); }
                $mix: map-get($value-to-unit, percent-negative);
                $mod: map-get($mix, $modifier) * -1;
            }
            @else {
                $mod: map-get($mix, $modifier);
            }
            @return  colorMode(scale-color( map-get($theme-map, $key), $lightness: $mod),$color-profile);
        }

    }
}
@function colorMode($color,$color-profile){
    $c:#000;
    @if $color-profile == RGB {
        $c: red($color),green($color),blue($color);
    }
    @if $color-profile == HSL {
        $c:hue($color),saturation($color),lightness($color);
    }
    @if( $color-profile == HEX ){
        $c: $color;
    }
    @return  $c;
}


@mixin color-loop($key, $color-name, $color, $bp: '', $state:'', $alpha: 1 , $color-profile: CSSVAR, $theme-map:  $theme-palette ) {
    
    $white-mix: map-get($value-to-unit, percent-positive);
    $black-mix: map-get($value-to-unit, percent-negative);
    $style: get-css-style($key);
    $unit: $color-name;
    $breakpoint: $bp;
    @if($state == 'active' ){
        $name-self: class-name-constructor($key, $unit, $breakpoint, 'is-active-self');
        $name-child: class-name-constructor($key, $unit, $breakpoint, 'is-active');
        #{$name-self},#{$name-child}{

            #{$style}: get-theme-color( $color-name , 0, $color-profile, $alpha, $theme-map ) !important ;
        }
        @each $val-name,
        $val in $white-mix {
            $unit: #{$color-name}-#{$val-name};
            $name-self: class-name-constructor($key, $unit, $breakpoint, 'is-active-self');
            $name-child: class-name-constructor($key, $unit, $breakpoint, 'is-active');
            #{$name-self},#{$name-child} {

                #{$style}: get-theme-color( $color-name , $val-name, $color-profile, $alpha, $theme-map ) !important ;
            }
        }
        @each $val-name,
        $val in $black-mix {
            $unit: #{$color-name}-#{$val-name};
            $name-self: class-name-constructor($key, $unit, $breakpoint, 'is-active-self');
        $name-child: class-name-constructor($key, $unit, $breakpoint, 'is-active');
            #{$name-self},#{$name-child} {

                #{$style}: get-theme-color( $color-name , $val-name, $color-profile, $alpha, $theme-map ) !important ;
            }
        }
        @if $debug-trace {
            @debug 'Color Loop for #{$key} painting it with #{$color-name}';
        }

    }
    // @else if($state == 'hover'){

    // }
    @else{
        $names: class-name-constructor($key, $unit, $breakpoint,$state);
        #{$names} {

            #{$style}: get-theme-color( $color-name , 0, $color-profile, 1, $theme-map ) !important ;
        }
        @each $val-name,
        $val in $white-mix {
            $unit: #{$color-name}-#{$val-name};
            $names: class-name-constructor($key, $unit, $breakpoint,$state);
            #{$names} {

                #{$style}: get-theme-color( $color-name , $val-name, $color-profile, 1, $theme-map ) !important ;
            }
        }
        @each $val-name,
        $val in $black-mix {
            $unit: #{$color-name}-#{$val-name};
            $names: class-name-constructor($key, $unit, $breakpoint,$state);
            #{$names} {

                #{$style}: get-theme-color( $color-name , $val-name, $color-profile, 1, $theme-map ) !important ;
            }
        }
        @if $debug-trace {
            @debug 'Color Loop for #{$key} painting it with #{$color-name}';
        }
    }
    
}


@mixin hover-loop($key, $color-name, $color, $bp: '', $state: 'hover') {

    @if $debug-trace {
        @debug 'hover-loop(#{$key}, #{$color-name}, #{$color}, #{$bp}, #{$state})';
    }
    @include color-loop($key, $color-name, $color, $bp, $state);
}

@mixin loop-transparent($key, $color: black, $breakpoint: '', $important: '', $hover: false) {
    $styles: get-css-style($key);
    $sizes: get-all-css-sizes($key);
    $hex: $color;
    @if (#{$color}=='white') {
        $hex: $white;
    }
    @else {
        $hex: $black;
    }
    @if $debug-trace {
        @debug 'Looping Style With Transparent Colors #{$key}';
    }
    @each $unit,
    $val in $sizes {
        $separator: '';
        @if ($unit !='') {
            $separator: '-';
        }
        $color-name: #{$color}#{$separator}#{$unit};
        $names: class-name-constructor($key, $color-name, $breakpoint);
        @if ($hover==true) {
            $names: class-name-constructor($key, $color-name, $breakpoint, 'hover');
        }
        #{$names} {
            @each $style in $styles {
                #{$style}: rgba($hex, $val) #{$important};
            }
        }
        @if $debug-trace {
            @debug 'My Name is  #{$names}';
        }
    }
}
// $key :  css class
// $color-name: the mapped color name to the object. 
// $color: hex value 

@mixin is-active-loop-transparent($key, $color:'black', $breakpoint: '', $important: '!important' , $hover: false) {
    $styles: get-css-style($key);
    $sizes: get-all-css-sizes($key);
    $hex: $color;
    @if (#{$color}=='white') {
        $hex: $white;
    }
    @else {
        $hex: $black;
    }

    @each $val-name, $val in $sizes {
        $unit: #{$color}-#{$val-name};
       
        @each $style in $styles { 
            $name-self: class-name-constructor($key, $unit, $breakpoint, 'is-active-self');
            $name-child: class-name-constructor($key, $unit, $breakpoint, 'is-active');
            
            #{$name-self},
            #{$name-child}  {
           
                #{$style}: rgba($hex, $val) #{$important};
            }
            
        }
        @if $debug-trace {
            @debug 'My Name is  #{$name-child}';
        }
    }
}


@mixin is-active-loop($key, $color-name, $color, $bp: '', $state:'active') {
    @include color-loop($key, $color-name, $color, $bp,$state);
    // $white-mix: map-get($value-to-unit, percent-positive);
    // $black-mix: map-get($value-to-unit, percent-negative);
    // $unit: $color-name;
    // $breakpoint: '' !default;
    // $name-self: class-name-constructor($key, $unit, $breakpoint, 'is-active-self');
    // $name-child: class-name-constructor($key, $unit, $breakpoint, 'is-active');
    // $styles: get-css-style($key);
    // #{$name-self},
    // #{$name-child} {
    //     @each $style in $styles {
    //         #{$style}: $color !important;
    //     }
    // }
    // @each $val-name,
    // $val in $white-mix {
    //     $unit: #{$color-name}-#{$val-name};
    //     $name-self: class-name-constructor($key, $unit, $breakpoint, 'is-active-self');
    //     $name-child: class-name-constructor($key, $unit, $breakpoint, 'is-active');
    //     #{$name-self},
    //     #{$name-child} {
    //         @each $style in $styles {
    //             #{$style}: mix(white, $color, $val) !important;
    //         }
    //     }
    // }
    // @each $val-name,
    // $val in $black-mix {
    //     $unit: #{$color-name}-#{$val-name};
    //     $name-self: class-name-constructor($key, $unit, $breakpoint, 'is-active-self');
    //     $name-child: class-name-constructor($key, $unit, $breakpoint, 'is-active');
    //     #{$name-self},
    //     #{$name-child} {
    //         @each $style in $styles {
    //             #{$style}: mix(black, $color, $val) !important;
    //         }
    //     }
    // }
    // @if $debug-trace {
    //     @debug 'Is Active Color Loop for #{$key} painting it with #{$color-name}';
    // }
}

@function theme-color($key: 'primary') {
    @return map-get($theme-palette, $key);
}