/*
Overflow

Control some basics of how content overflows.   

.overflow_hidden   - hidden
.overflow_auto    - auto
.overflow_visible    - visible
.overflow_scroll    - scroll
.overflow_clip    - clip
.overflow_ellipsis    - ellipsis
.overflow_unset   - unset

	Markup:
	<div class="br_solid br_transparent bg_hightlight-3 max-w_40 {{modifier_class}}">
		<p class="nowrap">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore quisquam vero, accusamus repellendus rerum sed dolorem quidem harum, neque odit dolor minima, quia et recusandae voluptatibus similique! Earum, unde laborum.</p>
	</div>

	MarkupWrapperClasses:
	grid template-x_30 texture_light overflow_hidden


Weight: 100

Styleguide Layouts.Display.overflow

*/

.overflow_hidden {
	overflow: hidden;
}

.overflow_visible {
	overflow: visible;
}

.overflow_auto {
	overflow: auto;
}

.overflow_scroll {
	overflow-x: scroll;
	overflow-y: scroll;
}

.overflow_scroll-x {
	overflow-x: scroll;
	overflow-y: hidden;
}
.overflow_scroll-y {
	overflow-x: hidden;
	overflow-y: scroll;
}


.overflow_clip {
	@extend .overflow_hidden;
	text-overflow: clip;
}

.overflow_ellipsis {
	@extend .overflow_hidden;
	text-overflow: ellipsis;
}

.overflow_unset {
	overflow: unset;
}


/*
Overflow Wrap

Overflow-wrap for handling really long words.   

.overflow-wrap_normal   - normal
.overflow-wrap_break   - break-word
.overflow-wrap_anywhere   - anywhere

	Markup:
	<div class="br_solid br_transparent bg_hightlight-3 max-w_20 {{modifier_class}}">
		<p>Author Relationships With Industry and Other Entities (Relevant)—2018 AHA/ACC/AACVPR/AAPA/ABC/ACPM/ADA/AGS/APhA/ASPC/NLA/PCNA Guideline on the Management of Blood Cholesterol (August 2018) e344</p>
	</div>

Weight: 110

Styleguide Layouts.Display.overflow-wrap

*/

.overflow-wrap_normal {
	overflow-wrap: normal;
}

.overflow-wrap_break {
	overflow-wrap: break-word;
}

.overflow-wrap_anywhere {
	overflow-wrap: anywhere;
}


/*
Box Decoration Break

Define how styling should span across visual fragments of one content element.   

.box-decoration_slice   - slice
.box-decoration_clone   - clone

	Markup:
	<div class="max-w_30">
		<p class="br_solid br_primary bg_hightlight-3 inline {{modifier_class}}">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore quisquam vero, accusamus repellendus rerum sed dolorem quidem harum, neque odit dolor minima, quia et recusandae voluptatibus similique! Earum, unde laborum.</p>
	</div>

	MarkupWrapperClasses:
	grid template-x_30 texture_light overflow_hidden

Weight: 120

Styleguide Layouts.Display.BoxDecorationBreak

*/

.box-decoration_slice {
	box-decoration-break: slice;
}

.box-decoration_clone {
	box-decoration-break: clone;
}