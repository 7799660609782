/*
Hover

Decorate containers and text to show user interaction with simple hover, and focus effects. 


Weight: 130

Styleguide Interaction.States.Hover
*/
/* No styleguide reference. */

/*
  Hover: Background Color
 
  hovers can easily be added to objects by adding `h:` in front of the background color utility like `h:bg_primary`

  bg_alert              - background color modified light to dark
  bg_primary              - background color modified light to dark
  bg_shade              - background color modified light to dark
  bg_info              - background color modified light to dark
  bg_highlight              - background color success color
  bg_success              - background color modified light to dark
  bg_warning              - background color modified light to dark

  Markup:
  <div class="flex m_5">
    <div class="h:{{modifier_class}}-5 flex_auto text_center br_1 br_solid br_shade-4 p_2">5</div>
    <div class="h:{{modifier_class}}-4 flex_auto text_center br_1 br_solid br_shade-4 p_2">4</div>
    <div class="h:{{modifier_class}}-3 flex_auto text_center br_1 br_solid br_shade-4 p_2">3</div>
    <div class="h:{{modifier_class}}-2 flex_auto text_center br_1 br_solid br_shade-4 p_2">2</div>
    <div class="h:{{modifier_class}}-1 flex_auto text_center br_1 br_solid br_shade-4 p_2">1</div>
    <div class="h:{{modifier_class}}-0 flex_auto text_center br_1 br_solid br_shade-4 p_2">0</div>
    <div class="h:{{modifier_class}}-n1 flex_auto text_center br_1 br_solid br_shade-4 p_2 h:c_white">n1</div>
    <div class="h:{{modifier_class}}-n2 flex_auto text_center br_1 br_solid br_shade-4 p_2 h:c_white">n2</div>
    <div class="h:{{modifier_class}}-n3 flex_auto text_center br_1 br_solid br_shade-4 p_2 h:c_white">n3</div>
    <div class="h:{{modifier_class}}-n4 flex_auto text_center br_1 br_solid br_shade-4 p_2 h:c_white">n4</div>
    <div class="h:{{modifier_class}}-n5 flex_auto text_center br_1 br_solid br_shade-4 p_2 h:c_white">n5</div>
  </div>

  Weight: 30
 
  Styleguide Interaction.States.Hover.background
 
 */
a:focus-visible {
	color: unset;
}
%hover_transition,
*[class*='hover:'],
*[class*='h\:'] {
	transition: all ease-out 0.2s; // used for backgrounds
	cursor: pointer;
	&:focus-visible {
		background-color: inherit;
		border-color: inherit;
		color: inherit;
	}
}
%hover_transition-in,
*[class*='hover:c'],
*[class*='h:c'] {
	transition: all ease-in 0.2s; // used for text
}

@each $color-name, $color in $theme-palette {
	@include hover-loop('background-color', $color-name, $color);
	@include hover-loop('color', $color-name, $color);
	@include hover-loop('border-color', $color-name, $color);
}

.h\:c_inherit{
  color:inherit;
}
/*
  Hover: Text Color
 
  Hovers can easily be added to change the text color by adding `h:` in front of the text color utility like `h:primary`

  c_primary              - text color modified light to dark
  c_shade              - text color modified light to dark
  c_info              - text color modified light to dark
  c_highlight              - text color success color
  c_success              - text color modified light to dark
  c_warning              - text color modified light to dark
  c_alert              - text color modified light to dark

  Markup:
  <div class="flex m_5">
    <div class="h:{{modifier_class}}-5 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1">5</div>
    <div class="h:{{modifier_class}}-4 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1">4</div>
    <div class="h:{{modifier_class}}-3 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1">3</div>
    <div class="h:{{modifier_class}}-2 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1">2</div>
    <div class="h:{{modifier_class}}-1 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1">1</div>
    <div class="h:{{modifier_class}}-0 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1">0</div>
    <div class="h:{{modifier_class}}-n1 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1 ">n1</div>
    <div class="h:{{modifier_class}}-n2 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1 ">n2</div>
    <div class="h:{{modifier_class}}-n3 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1 ">n3</div>
    <div class="h:{{modifier_class}}-n4 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1 ">n4</div>
    <div class="h:{{modifier_class}}-n5 flex_auto font_bold bg_shade-3 font_4 font_accent: text_center br_1 br_solid br_shade-4 p_1 ">n5</div>
  </div>

  Weight: 10
 
  Styleguide Interaction.States.Hover.text
 
 */

#{ class-name-constructor(color, white, "", "hover")},
#{ class-name-constructor(color, white, "", "is-active")},
#{ class-name-constructor(color, white, "", "is-active-self")} {
	color: $white !important;
}
#{ class-name-constructor(color, black, "", "hover")},
#{ class-name-constructor(color, black, "", "is-active")},
#{ class-name-constructor(color, black, "", "is-active-self")} {
	color: $black !important;
}


.h\:underline {
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.h\:underline-none,
.h\:undecorated {
	&:hover,
	&:focus {
		text-decoration: none !important;
	}
}

.h\:dashed {
	&:hover,
	&:focus {
		text-decoration: underline;
		text-decoration-style: dashed;
	}
}

.h\:double {
	&:hover,
	&:focus {
		text-decoration: underline;
		text-decoration-style: double;
	}
}

.h\:dotted {
	&:hover,
	&:focus {
		text-decoration: underline;
		text-decoration-style: dotted;
	}
}

/*
  Hover:  Underline 
 
  Sometimes is is needed to add an underline on a piece of text when it hovers adding `h:` in front of the type of underline you would like gives that type of underline to the text when hovered.

  underline.c_primary - classic anchor text
  dotted.c_warning - great for popups or hint text
  double.c_highlight - extra loud underlines for the really important links or modals
  dashed.c_accent - extra loud dots for info and hint texts reveals.

  Markup:
  <p class="font_1">
    Lorem ipsum dolor sit amet, 
    <span class="h:{{modifier_class}} h:c_primary-n3">consectetur</span>
    adipisicing elit. 
    <span class="h:{{modifier_class}} h:c_primary-n3">Tenetur, similique</span>. 
    Sapiente facilis ipsam unde, dolorem illo ullam, tempore quos maiores quaerat quibusdam voluptatibus laboriosam. Nisi 
    <span class="h:{{modifier_class}} h:c_primary-n3">harum magni</span>
        adipisicing elit aut nulla et.
  </p>

  Weight: 20

  Styleguide Interaction.States.Hover.underlines
  
  */

/* Transparent Loop */
#{ class-name-constructor(background-color, white, "", "hover")},
#{ class-name-constructor(background-color, white, "", "is-active")},
#{ class-name-constructor(background-color, white, "", "is-active-self")} {
	background-color: $white;
}
@include loop-transparent(background-transparent, white, '', '!important', true);

#{ class-name-constructor(background-color, black, "", "hover")},
#{ class-name-constructor(background-color, black, "", "is-active")},
#{ class-name-constructor(background-color, black, "", "is-active-self")} {
	background-color: $black;
}
@include loop-transparent(background-transparent, black, '', '!important', true);

/*
Hover:  Background Black and White Opacity 

black and white overlays can be added to hovers easily by adding `h:` in front of black or white  with the level of opacity needed.

bg_white              - text color modified light to dark
bg_black              - text color modified light to dark

Markup:
<div class="flex m_5 bg_primary m_5">
  <div class="h:{{modifier_class}}-0  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">0</div>
  <div class="h:{{modifier_class}}-1  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">1</div>
  <div class="h:{{modifier_class}}-2  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">2</div>
  <div class="h:{{modifier_class}}-3  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">3</div>
  <div class="h:{{modifier_class}}-4  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">4</div>
  <div class="h:{{modifier_class}}-5  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">5</div>
  <div class="h:{{modifier_class}}-6  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">6</div>
  <div class="h:{{modifier_class}}-7  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">7</div>
  <div class="h:{{modifier_class}}-8  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">8</div>
  <div class="h:{{modifier_class}}-9  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">9</div>
  <div class="h:{{modifier_class}}  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">default</div>
</div>

Weight: 50

Styleguide Interaction.States.Hover.opacity.whiteblack
 
 */

/*
Hover: Full Opacity

Set the Opacity of an object to 100% while hovered.


Markup:
<div class="flex m_5 bg_primary m_5">
  <div class="h:opacity opacity-0  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">0</div>
  <div class="h:opacity opacity-1  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">1</div>
  <div class="h:opacity opacity-2  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">2</div>
  <div class="h:opacity opacity-3  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">3</div>
  <div class="h:opacity opacity-4  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">4</div>
  <div class="h:opacity opacity-5  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4">5</div>
  <div class="h:opacity opacity-6  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">6</div>
  <div class="h:opacity opacity-7  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">7</div>
  <div class="h:opacity opacity-8  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">8</div>
  <div class="h:opacity opacity-9  p-y_3 p-x_4 flex_auto font_bold font_2 font_accent: text_center br_1 br_solid br_shade-4 ">9</div>
</div>

Weight: 60

Styleguide Interaction.States.Hover.opacity

*/
#{class-name-constructor(opacity,"", "","hover")},
#{class-name-constructor(opacity,"", "","is-active")},
#{class-name-constructor(opacity,"", "","is-active-self")} {
	opacity: 1;
}

/*
  Hover:  Border Color
 
  hovers can easily effect the border style to toggle between styles.  Very useful fo toggling from a border type to none or vice versa. 
| Class          | Effect                                                                                      |
| -------------- | ------------------------------------------------------------------------------------------- |
| br_none        | Border is None on Hover                                                                     |
| br_dotted      | Border is Dotted on Hover                                                                   |
| br_dashed      | Border is Dashed on Hover                                                                   |
| br_solid       | Border is Solid on Hover                                                                    |
| br_transparent | Sometimes shifting to transparent is better so as to not cause the UI to flicker and resize |

Markup:
<div class="">
  <div class="h:br_none br_3 br_solid br_primary-2 p_2 m_2 bg_shade-5">None</div>
  <div class="h:br_transparent br_3 br_solid br_primary-2 p_2 m_2 bg_shade-5">Transparent</div>
  <div class="h:br_solid br_3 br_dotted br_primary-2 p_2 m_2 bg_shade-5">Solid</div>
  <div class="h:br_dashed br_3 br_solid br_primary-2 p_2 m_2 bg_shade-5">Dashed</div>
  <div class="h:br_dotted br_3 br_solid br_primary-2 p_2 m_2 bg_shade-5">Dotted</div>
</div>

Weight: 40

Styleguide Interaction.States.Hover.borders

*/
#{ class-name-constructor(border-style,dotted,"","hover")} {
	border-style: dotted !important;
}
#{ class-name-constructor(border-style,dashed,"","hover")} {
	border-style: dashed !important;
}
#{ class-name-constructor(border-style,solid,"","hover")} {
	border-style: solid !important;
}
#{ class-name-constructor(border-style,none,"","hover")} {
	border-style: none !important;
}
#{ class-name-constructor(border-color,transparent,"","hover")} {
	border-color: transparent !important;
}

