// Setting up the ability to shift the typography branding to a CSS property override. 
// Nothing in here can have important! to reduce the need for overrides.
.reading-typography{
	text-rendering: auto;

}
:where(.reading-typography) {
	font-family: var(--font-family_copy);
	font-weight: var(--font_regular);
	font-size: $font_0;
	// a and links are taken care of in the _link-decoration.scss
	:where(h1,h2,h3,h4,h5,h6){
		color: inherit;
		margin-bottom: $vertical-space * 0.5;
	}
	// h1:not(:first-child),
	// h2:not(:first-child),
	// h3:not(:first-child),
	// h4:not(:first-child),
	// h5:not(:first-child),
	// h6:not(:first-child) 
	:where(h1,h2,h3,h4,h5,h6):not(:first-child){
		margin-top: $vertical-space;
	}
	:where(h1,h2,h3,h4,h5,h6) + :where(h1,h2,h3,h4,h5,h6):not(:first-child){
		margin-top: $vertical-space * -.25;
	}
	%header{
		font-family: var(--font-family_display);
	}
	%copy{
		font-family: var(--font-family_copy);
		line-height: $line-height_2;
	}
	%lh_1 {
		@extend %header;
		line-height: $line-height_1;
	}
	%lh_2 {
		@extend %header;
		line-height: $line-height_2;
	}
	%lh_3 {
		@extend %header;
		line-height: $line-height_3;
	}
	h1 {
		@extend %lh_1;
		font-size: $font_5;
	}
	h2 {
		@extend %lh_1;
		font-size: $font_4;
	}
	h3 {
		@extend %lh_1;
		font-size: $font_3;
	}
	h4 {
		@extend %lh_2;
		font-size: $font_2;
	}
	h5 {
		@extend %lh_2;
		font-size: $font_1;
		font-weight: var(--font_bold);
	}
	h6 {
		@extend %lh_2;
		font-size: $font_1;
		font-weight: var(--font_bold);
	}
	p {
		@extend %copy;
	}
	li {
		margin-top: $margin_2;
		margin-bottom: $margin_3;
		padding-left: $padding_2;
		@extend %copy;
	}
	ul,
	ol {
		list-style-position: outside;
		padding-left: $padding_5;
	}
	ul {
		list-style-type: disc;
	}
	ul ul {
		list-style-type: circle;
	}
	ol {
		list-style-type: decimal;
	}
	ol ol {
		list-style-type: lower-alpha;
	}
	// nested lists
	li > ul,
	li > ol {
		margin-left: $margin_4;
		padding-left: $padding_4;
	}
	li + li {
		margin-top: $margin_3;
	}
	li:last-child {
		margin-bottom: $margin_3;
	}
	p + p {
		margin-top: $margin_3;
	}
	abbr {
		text-decoration: 1px dotted inherit;
	}
	hr {
		margin: $margin_4 0;
	}
	img, a img {
		width: 100%;
		margin: 0;
		border: 0px none;
	}
	a:has(> img){
		display:inline-block;
	}
	p,
	ul,
	ol,
	dl,
	table {
		margin-top: $margin_3;
		margin-bottom: $margin_4;
		font-size: max(#{$font_0}, #{$reading-min});
	}
	table{
		color:inherit;
		border-color: inherit;
	}
	table {
		width: 100%;
	}
	thead {
		border-block-end: 1px solid currentColor;
	}
	&.no-margins {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0 !important;
		}
	}
	code {
		font-family: $font_mono;
		color:inherit;
		
	}
	a:not([href],[class]), a:not([href],[class]):hover {
		color: inherit;
		text-decoration: none;
	  }

	  a:not(.btn, .not-link){
        @extend %link-dark;
		font-weight: var(--font_medium);
    }
}

:where(.reading-typography):not(.color_inherit){
	:where(h1,h2,h3,h4,h5,h6){
		color: var(--custom-color,#{$headline-color});
	}
	table{
		
		border-color: var(--custom-color,#{$headline-color});
	}
	thead {
		color:var(--custom-color,#{$headline-color});
		border-block-end-color: var(--custom-color,#{$headline-color});
	}
	code{color: var(--code-color,get-theme-color(accent, 0,CSSVAR));}
	abbr {
		text-decoration: 1px dotted get-theme-color(highlight, 0,CSSVAR);
	}
	::marker{
		color:var(--custom-color,currentColor);
	}

}

.color_inherit:where(.reading-typography){
	--custom-color:inherit;
	:where(h1,h2,h3,h4,h5,h6){
		color: var(--custom-color,inherit);
	}
	table{
		
		border-color: var(--custom-color,inherit);
	}
	thead {
		color:var(--custom-color,inherit);
		border-block-end-color: var(--custom-color,inherit);
	}
	code{color:currentColor;}
	abbr {
		text-decoration: 1px dotted var(--custom-color,inherit);;
	}
	::marker{
		color:var(--custom-color,currentColor);
	}


}
.color_inherit:is(.reading-typography){
	a:not(.btn,.not-link){
		@extend %link-inherit;
	}
}

:root{
	--custom-font-size-modifier: 1.5;

}


.font-size_up:where(.reading-typography) {
	--custom-font-size-up: var(--custom-font-size-modifier);
	font-size: calc(#{$font_0} * var(--custom-font-size-down));
	h1 {
		font-size: calc(#{$font_6} * var(--custom-font-size-up));
	}
	h2 {
		font-size: calc(#{$font_5} * var(--custom-font-size-up));
	}
	h3 {
		font-size: calc(#{$font_4} * var(--custom-font-size-up));
	}
	h4 {
		font-size: calc(#{$font_3} * var(--custom-font-size-up));
	}
	h5, h6 {
		font-size: calc(#{$font_2} * var(--custom-font-size-up));
	}
	p,
	ul,
	ol,
	dl,
	table {
		font-size: calc(#{$font_1} * var(--custom-font-size-up) );
	}
}
.font-size_down:where(.reading-typography) {
	--custom-font-size-down:calc( 1/ var(--custom-font-size-modifier) );
	font-size: calc(#{$font_0} * var(--custom-font-size-down));
	h1 {
		font-size: calc(#{$font_4} * var(--custom-font-size-down));
	}
	h2 {
		font-size: calc(#{$font_3} * var(--custom-font-size-down));
	}
	h3 {
		font-size: calc(#{$font_2} * var(--custom-font-size-down));
	}
	h4 {
		font-size: calc(#{$font_1} * var(--custom-font-size-down));
	}
	h5 {
		font-size: calc(#{$font_0} * var(--custom-font-size-down));
	}
	h6 {
		font-size: calc(#{$font_0} * var(--custom-font-size-down));
	}
	p,
	ul,
	ol,
	dl,
	table {
		font-size: calc(#{$font_0} * var(--custom-font-size-down));
		margin-block-start: $margin_2;
		margin-block-end: $margin_3;
	}
}
.links_dark.reading-typography a:not(.btn,.not-link){ @extend %link-dark;}
.links_light.reading-typography a:not(.btn,.not-link){ @extend %link-light;}
.links_inherit.reading-typography a:not(.btn,.not-link){ @extend %link-inherit;}
