/*

Expanded Click Area

allows for a relative container to be clickable by the link. 

Markup:
<div class="card shadow_2 cell small-6 large-4 max-w_40"  data-style="{{ modifier_class }}" >
  <div class=" font_accent: border-top-left-square c_white font_3 p_4 texture_dust card-divider bg_primary card-header">
    This is a header
  </div>
  <img src="https://picsum.photos/400/300">
  <div class="card-section card-body">
    <h4>This is a card.</h4>
	 <p>It has an easy to override visual style, and is appropriately subdued.</p>
	 <a href="#" class="expanded-click-area button btn btn-primary">I am so clickable</a>
  </div>
</div>

Styleguide Recipes.InteractiveExtras.Expanded Click Area
*/

.expanded-click-area::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color:transparent;
}
.expanded-click-area-beforeAlt::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color:transparent;
}
.sfPageEditor .expanded-click-area::after {
    background-color: #9acd322e
}