
/**
 * arches - Global style system focused on utility classes
 * @version v4.1.4x
 * @link https://github.com/ACC-Style/Arches/
 */



/**  Build Comment: including file /setup/__preheader.scss  **/ 
@use "sass:math";
@use "sass:color";

// Consider using https://www.purgecss.com/ to remove styles not used in the project.
/** SCSS DOC: _preheader.scss **/
$debug-trace: false !default;
$name-mode: 'SHORT' !default; // $name-mode: "SHORT","LONG","ALL"
$ms-base: 1rem !default; // needs to be in rem's
$colors: ();
$grays: ();
$theme-palette: ();
$usingFontAwesome:true;
@import 'helpers/helpers';
@import 'vendor/modular-scale';
/** SCSS DOC: _utility-css.vars.scss **/
@import 'setup/config/utility-css.vars';
@import 'helpers/breakpoint';
%font-awesome-pro {
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-family: 'Font Awesome 6 Pro', 'Font Awesome 5 Pro';
	font-weight: 900;
}




/**  Build Comment: This Style Sheet should only be used as an augmenting stylesheet  **/ 

$active-class-name: 'active';


/**  Build Comment: Set Active Class  **/ 
/** SCSS DOC: __brand.base.scss **/
/**  Colors  **/

@import 'setup/config/colors';
/**  Fonts  **/
@import 'setup/config/fonts';
/**  Textures  **/
@import 'setup/config/textures';

/**  logos  **/
@import 'setup/config/logo';



// reading-typography
$headline-color: inherit !default;
$reading-min:16px;
/** SCSS DOC: __brand.acc.scss **/
/**  ACC Brands    *********************************/

$teal: #198dae;
$green-blue: #16a385;
$dark-grey: #676a72;
$alt-grey: #a3988c;
$green: #078a2e;
$orange: #da8210;
$red: #dd1a1a;
$blue: #2b58c0;
$purple: #7742bd;
$magenta: #c34198;
$acc-blue: #004176;
$white: rgb(255, 255, 255);
$black: rgb(19, 18, 18);
$real-black: #000;
// Currently Un used in the design but part of bootstrap.
$indigo: #6610f2 !default;
$pink: #e83e8c !default;
$yellow: #ffc107 !default;
$cyan: #17a2b8 !default;
$gray-100: mix($white, $black, 10%);
$gray-200: mix($white, $black, 20%);
$gray-300: mix($white, $black, 30%);
$gray-400: mix($white, $black, 40%);
$gray-500: mix($white, $black, 50%);
$gray-600: mix($white, $black, 60%);
$gray-700: mix($white, $black, 70%);
$gray-800: mix($white, $black, 80%);
$gray-900: mix($white, $black, 90%);
$theme-palette: map-merge(
    $theme-palette,
    (
        primary: $teal,
        secondary: $dark-grey,
        success: $green,
        warning: $orange,
        alert: $red,
        //navigation: $blue,
        accent: $green-blue,
        info: $alt-grey,
        highlight: $purple,
        acc: $acc-blue,
    )
);
$fonts: (
    accent: (
        name: 'Roboto Slab',
        short-name:"RS",
        stack: '"Roboto Slab","Times New Roman","Lucida Bright",Georgia,serif, "slab"',
        weight:(
			light:200,
			regular:400,
			medium:500,
			bold:700,
			xbold:900
		)
        ),
    display: (
        name: 'Maven Pro',
        short-name:"MP",
        stack: '"Maven Pro","Muli","Open Sans",Verdana,Helvetica,sans-serif',
        weight:(
			light:400,
			regular:450,
			medium:550,
			bold:700,
			xbold:900
		)
    ),
    copy: (
        name: 'Open Sans',
        short-name:"OS",
        stack: '"Open Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        weight:(
			light:300,
			regular:400,
			medium:500,
			bold:675,
			xbold:800
		)
        ),
    ui: (
        name: 'Open Sans',
        short-name:"OS",
        stack: '"Open Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        weight:(
			light:300,
			regular:400,
			medium:500,
			bold:675,
			xbold:800
		)
    ),
);
// Reset Values of Utlity-css.vars.scss
$value-to-unit: map-deep-set($value-to-unit, font-family accent, unquote(map-deep-get($fonts, accent, stack)));
$value-to-unit: map-deep-set($value-to-unit, font-family display, unquote(map-deep-get($fonts, display, stack)));
$value-to-unit: map-deep-set($value-to-unit, font-family copy, unquote(map-deep-get($fonts, copy, stack)));
$value-to-unit: map-deep-set($value-to-unit, font-family ui, unquote(map-deep-get($fonts, ui, stack)));



$font_display: get-css-size(font-family, display);
$font_accent: get-css-size(font-family, accent);
$font_ui: get-css-size(font-family, ui);
$font_copy: get-css-size(font-family, copy);
$font_mono: get-css-size(font-family, mono);
$global-radius-on: false;
$global-border-radius: 6px;
$value-to-unit: map-deep-set($value-to-unit, 'radius' 'radius', $global-border-radius);
$global-border-round: 25px;
$value-to-unit: map-deep-set($value-to-unit, 'radius' 'round', $global-border-round);

$basicSizeMultiplier: 1rem;

$h1-font-size: map-deep-get($value-to-unit, font-size, 5);
$h2-font-size: map-deep-get($value-to-unit, font-size, 4);
$h3-font-size: map-deep-get($value-to-unit, font-size, 3);
$h4-font-size: map-deep-get($value-to-unit, font-size, 2);
$h5-font-size: map-deep-get($value-to-unit, font-size, 1);
$h6-font-size: map-deep-get($value-to-unit, font-size, 0);
$font-size-base: $base-font-size-rem; // Assumes the browser default, typically `16px`
$font-size-lg: map-deep-get($value-to-unit, font-size, 2);
$font-size-sm: map-deep-get($value-to-unit, font-size, n1);
$headline-color: get-theme-color(primary, -1, CSSVAR);


:root{
    --code-color: #{get-theme-color(accent, -3,CSSVAR)};
}

/**  Build Comment: Overlay and alter for acc Branding 4.1.4x  **/ 
/** SCSS DOC:_brand.compile.scss **/
@import 'setup/config/__root.cssvars';

/**  Build Comment: Render Root Variables  **/ 
/** SCSS DOC: __var.output.scss **/
/** This needs to run later then brand **/

$margin_0: get-css-size(margin, 0);
$margin_1: get-css-size(margin, 1);
$margin_2: get-css-size(margin, 2);
$margin_3: get-css-size(margin, 3);
$margin_4: get-css-size(margin, 4);
$margin_5: get-css-size(margin, 5);
$margin_n1: get-css-size(margin, n1);
$margin_n2: get-css-size(margin, n2);
$margin_n3: get-css-size(margin, n3);
$margin_n4: get-css-size(margin, n4);
$margin_n5: get-css-size(margin, n5);
$padding_0: get-css-size(padding, 0);
$padding_1: get-css-size(padding, 1);
$padding_2: get-css-size(padding, 2);
$padding_3: get-css-size(padding, 3);
$padding_4: get-css-size(padding, 4);
$padding_5: get-css-size(padding, 5);
$border_square: 0;
$border_radius: get-css-size(border-radius, radius);
$border_round: get-css-size(border-radius, round);
$border_circle: get-css-size(border-radius, circle);
$border-width_0: get-css-size(border-width, 0);
$border-width_1: get-css-size(border-width, 1);
$border-width_2: get-css-size(border-width, 2);
$border-width_3: get-css-size(border-width, 3);
$border-width_4: get-css-size(border-width, 4);
$border-width_5: get-css-size(border-width, 5);
$font_display: var(--font-family_display);
$font_accent: var(--font-family_accent);
$font_ui: var(--font-family_ui);
$font_copy: var(--font-family_copy);
$font_mono: var(--font-family_mono);
$font_6: get-css-size(font-size, 6);
$font_5: get-css-size(font-size, 5);
$font_4: get-css-size(font-size, 4);
$font_3: get-css-size(font-size, 3);
$font_2: get-css-size(font-size, 2);
$font_1: get-css-size(font-size, 1);
$font_0: get-css-size(font-size, 0);
$font_n1: get-css-size(font-size, n1);
$font_n2: get-css-size(font-size, n2);
$font_n3: get-css-size(font-size, n3);
$font_n4: get-css-size(font-size, n4);
$font_n5: get-css-size(font-size, n5);
$font_bold: var(--font_bold);
$font_medium: var(--font_medium);
$font_xbold: var(--font_xbold);
$font_light: var(--font_light);
$font_regular: var(--font_regular);
$line-height_0: get-css-size(line-height, 0);
$line-height_1: get-css-size(line-height, 1);
$line-height_2: get-css-size(line-height, 2);
$line-height_3: get-css-size(line-height, 3);
$line-height_4: get-css-size(line-height, 4);
$line-height_5: get-css-size(line-height, 5);
/** SCSS DOC: _setup.none.scss **/

/**  Build Comment: Basic UC File  **/ 

/**  Utility Classes    *********************************/
/**  Logos  **/
@import "utility-css/__index";
/** SCSS DOC: __globalshame_uc.scss **/