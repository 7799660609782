/*
Logos

Logos in the design come in 4 variations the primary logo, the condensed logo, and alte version which are the reverse of primary and reversed.  All logos are controled by the width and will maintain there aspect ratios to respond to the container they are in.  For 508 compliance please place the brand name in the logo.

.logo_primary	            		-   	Primary Logo great for desktop displays best used for when the logo can be taller then 80px.
.logo_primary-alt 	            	-   	Primary Logo reversed for dark background situations.
.logo_condensed.max-w_15             -  	 	Smaller Mobile Friendly Logo these logos and be readable between 40px to 80px in height. 
.logo_primary-alt.max-w_15         -  	 	Smaller Logo Reversed.

Markup:
<div class="{{modifier_class}}" data-style="{{ modifier_class }}" >
	<span class="alt">{{modifier_class}}</span>
</div>

MarkupWrapperClasses:
    bg_shade-2 max-w_40

Styleguide DesignBase.Logos

*/

[class*='logo_'],
[class*='seal_'] {
	background-color: transparent;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	text-indent: -9999px;
	overflow: hidden;
	&:before {
		content: '';
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
	}
	&:after {
		content: '';
		display: table;
		clear: both;
	}
	> .alt-text:before {
		display: none;
	}
}

.logo_primary {
	background-image: url(map-deep-get($logo, primary, url));
	&:before {
		padding-top: map-deep-get($logo, primary, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($logo, primary, alt);
	}
}
.logo_primary-alt {
	background-image: url(map-deep-get($logo, primary_alt, url));
	&:before {
		padding-top: map-deep-get($logo, primary_alt, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($logo, primary_alt, alt);
	}
}
.logo_condensed {
	background-image: url(map-deep-get($logo, condensed, url));
	&:before {
		padding-top: map-deep-get($logo, condensed, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($logo, condensed, alt);
	}
}
.logo_condensed-alt {
	background-image: url(map-deep-get($logo, condensed_alt, url));
	&:before {
		padding-top: map-deep-get($logo, condensed_alt, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($logo, condensed_alt, alt);
	}
}

/*
Seals

Seals in the design come in 3 variations the default, small, and tiny.  All Seals will maintain their aspect ratios and will respond to the container, but seals have a max width.  For 508 compliance, please place the brand name in the seal.

.seal_normal	            - Full size seal great for desktop displays best used for when the seal can be taller then 100px.
.seal_small	            - Condensed size seal great for Tablet displays best used for when the seal is between 100px to 40px.
.seal_tiny	            - Smallest of the seals best used for icons when seal is 40px and bellow


Markup:
<div class="{{modifier_class}} m_auto" data-style="{{ modifier_class }}">
	<span class="alt-text">{{modifier_class}}</span>
</div>

MarkupWrapperClasses:
	bg_shade-2 max-w_40

Styleguide DesignBase.Seals

*/

.seal_normal {
	background-image: url(map-deep-get($seal, normal, url));
	&:before {
		padding-top: map-deep-get($seal, normal, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($seal, normal, alt);
	}
}
.seal_small {
	background-image: url(map-deep-get($seal, small, url));
	max-width: 100px;
	&:before {
		padding-top: map-deep-get($seal, small, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($seal, small, alt);
	}
}
.seal_tiny {
	background-image: url(map-deep-get($seal, tiny, url));
	max-width: 40px;
	&:before {
		padding-top: map-deep-get($seal, tiny, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($seal, tiny, alt);
	}
}

.seal_normal-alt {
	background-image: url(map-deep-get($seal, normal_alt, url));
	&:before {
		padding-top: map-deep-get($seal, normal_alt, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($seal, normal_alt, alt);
	}
}
.seal_small-alt {
	background-image: url(map-deep-get($seal, small_alt, url));
	max-width: 100px;
	&:before {
		padding-top: map-deep-get($seal, small_alt, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($seal, small_alt, alt);
	}
}
.seal_tiny-alt {
	background-image: url(map-deep-get($seal, tiny_alt, url));
	max-width: 40px;
	&:before {
		padding-top: map-deep-get($seal, tiny_alt, aspectratio) * 100%;
	}
	> span.alt-text:before {
		content: map-deep-get($seal, tiny_alt, alt);
	}
}
