/*

Scrollbar Mini

Give a mini scroll bar to a content area. 

Markup:
<div class="card shadow_2 cell small-6 large-4 max-w_30" >
    <div class=" font_accent: border-top-left-square c_white font_3 p_4 texture_dust card-divider bg_primary card-header">
    This is a header
    </div>
    <div class="card-section card-body p_4 scrollbar-mini overflow_auto" style="max-height:300px;">
        <h4>This is a card.</h4>
        <p class="font_1">It has an easy to override visual style, and is appropriately subdued.</p>
        <img src="https://picsum.photos/400/300">
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi dolorem et maxime, officiis nulla vel? Numquam quas voluptatibus, voluptatem fuga saepe animi nemo voluptatum repellendus in natus libero, nobis illum!</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi dolorem et maxime, officiis nulla vel? Numquam quas voluptatibus, voluptatem fuga saepe animi nemo voluptatum repellendus in natus libero, nobis illum!</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi dolorem et maxime, officiis nulla vel? Numquam quas voluptatibus, voluptatem fuga saepe animi nemo voluptatum repellendus in natus libero, nobis illum!</p>  
    </div>
    <div class="card-divider card-footer br_0 br_solid br-t_1 br_primary"> <a href="#" class="button btn btn-primary">I am so clickable</a></div>
</div>

Styleguide Recipes.InteractiveExtras.Scrollbar
*/

/* Let's get this party started */
.scrollbar-mini::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}
/* Track */
.scrollbar-mini::-webkit-scrollbar-track {
    box-shadow: make-shadow(2, 'inset', #000, 0.2);
    background-color: get-theme-color(shade, -3, CSSVAR, 0.1);
}
/* Handle */
.scrollbar-mini::-webkit-scrollbar-thumb {
    border-radius: get-css-size(border-radius, round);
    background: get-theme-color(accent, -1, CSSVAR);
    box-shadow: make-shadow(2, 'inset', #000, 0.2);
}
.scrollbar-mini::-webkit-scrollbar-thumb:window-inactive {
    background: get-theme-color(accent, -1, CSSVAR, 0.1);
}
.scrollbar-mini::-webkit-scrollbar-track:window-inactive {
    background: get-theme-color(shade, -3, CSSVAR, 0.1);
}
