@use "sass:map";
@use "sass:selector";
@use "sass:color";
@use "sass:math";
/** SCSS DOC: _root.cssvars.scss **/
:root {
	@each $color, $value in $theme-palette {
		--#{$color}-h: #{color.hue($value)};
		--#{$color}-s: #{color.saturation($value)};
		--#{$color}-l: #{color.lightness($value)};
		--#{$color}: hsl( var(--#{$color}-h),var(--#{$color}-s),var(--#{$color}-l) );
	}
	// $link-color-light:					get-theme-color(highlight, 3) !default;
	// $link-color-light--hover:			get-theme-color(highlight, 5) !default;
	// $link-color-light--visited:			color.scale( get-theme-color(highlight, 2), $saturation: -70%) !default;
	// $link-color-dark:					get-theme-color(highlight, -3) !default;
	// $link-color-dark--hover:			get-theme-color(highlight, -5) !default;
	// $link-color-dark--visited:			color.scale( get-theme-color(highlight, -2), $saturation: -70%) !default;
	
	
	--link-color-light-h:#{color.hue($link-color-light)};
	--link-color-light-s:#{color.saturation($link-color-light)};
	--link-color-light-l:#{color.lightness($link-color-light)};
	--link-color-light: hsl( var(--link-color-light-h),var(--link-color-light-s),var(--link-color-light-l) );
	--link-color-light--hover-h:#{color.hue($link-color-light--hover)};
	--link-color-light--hover-s:#{color.saturation($link-color-light--hover)};
	--link-color-light--hover-l:#{color.lightness($link-color-light--hover)};
	--link-color-light--hover: hsl( var(--link-color-light--hover-h),var(--link-color-light--hover-s),var(--link-color-light--hover-l) );
	--link-color-light--visited-h:#{color.hue($link-color-light--visited)};
	--link-color-light--visited-s:#{color.saturation($link-color-light--visited)};
	--link-color-light--visited-l:#{color.lightness($link-color-light--visited)};
	--link-color-light--visited: hsl( var(--link-color-light--visited-h),var(--link-color-light--visited-s),var(--link-color-light--visited-l) );
	

	--link-color-dark-h:#{color.hue($link-color-dark)};
	--link-color-dark-s:#{color.saturation($link-color-dark)};
	--link-color-dark-l:#{color.lightness($link-color-dark)};
	--link-color-dark: hsl( var(--link-color-dark-h),var(--link-color-dark-s),var(--link-color-dark-l) );
	--link-color-dark--hover-h:#{color.hue($link-color-dark--hover)};
	--link-color-dark--hover-s:#{color.saturation($link-color-dark--hover)};
	--link-color-dark--hover-l:#{color.lightness($link-color-dark--hover)};
	--link-color-dark--hover: hsl( var(--link-color-dark--hover-h),var(--link-color-dark--hover-s),var(--link-color-dark--hover-l) );
	--link-color-dark--visited-h:#{color.hue($link-color-dark--visited)};
	--link-color-dark--visited-s:#{color.saturation($link-color-dark--visited)};
	--link-color-dark--visited-l:#{color.lightness($link-color-dark--visited)};
	--link-color-dark--visited: hsl( var(--link-color-dark--visited-h),var(--link-color-dark--visited-s),var(--link-color-dark--visited-l) );

}

@if($usingFontAwesome){
	%font-awesome-pro {
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		font-family: 'Font Awesome 6 Pro', 'Font Awesome 5 Pro';
		font-weight: 900;
	}
}
