/*
#{$active-class-name}

A common class name given to the parent container can effect the color, background and opacity of the children.  The active class is different depending if you are using Bootstrap or Foundation. <h1>This Variation of Arches uses <strong>"#{$active-class-name}"</strong></h1>  

Weight: 135

Styleguide Interaction.States.Active

*/

/*
#{$active-class-name} Full Opacity

<div class="p-b_3"><span class="bg_highlight br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Click the areas below to toggle the `#{$active-class-name}` state.</span></div><strong>`.#{$active-class-name} .a:opacity`</strong> Set the Opacity of an objects children to 100% when it has the class is-active. 

Markup:
<div class="flex p_4 bg_primary is-active_toggle-function ">
  <div class="flex_auto opacity_0 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 0 </div>
  <div class="flex_auto opacity_1 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 1 </div>
  <div class="flex_auto opacity_2 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 2 </div>
  <div class="flex_auto opacity_3 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 3 </div>
  <div class="flex_auto opacity_4 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 4 </div>
  <div class="flex_auto opacity_5 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 5 </div>
  <div class="flex_auto opacity_6 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 6 </div>
  <div class="flex_auto opacity_7 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 7 </div>
  <div class="flex_auto opacity_8 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 8 </div>
  <div class="flex_auto opacity_9 a:opacity br_1 br_solid br_white p_2 bg_warning c_white"> 9 </div>
</div>

Weight: 60

Styleguide Interaction.States.Active.opacity

*/

/*
#{$active-class-name} Black n White 

<div class="p-b_3"><span class="bg_highlight br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Click the areas below to toggle the "#{$active-class-name}" state.</span></div>Set the background to full white or black when the parent has a class of "#{$active-class-name}". 

bg_white              - text color modified light to dark
bg_black              - text color modified light to dark

Markup:
<div class="flex p_5 bg_primary m_5 is-active_toggle-function">
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-0 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4">0</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-1 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4">1</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-2 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4">2</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-3 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4">3</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-4 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4">4</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-5 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4">5</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-6 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4 ">6</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-7 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4 ">7</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-8 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4 ">8</div>
  <div class="flex_auto a:{{modifier_class}} bg_{{modifier_class}}-9 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4 ">9</div>
  <div class="flex_auto  a:{{modifier_class}} bg_{{modifier_class}}-0 p-y_3 p-x_4 font_bold font_2 font_accent: c_shade text_center br_1 br_solid br_shade-4 ">default</div>
</div>

Weight: 50

Styleguide Interaction.States.Active.black and white
 
 */
/*
  #{$active-class-name} font color
 
  <div class="p-b_3"><span class="bg_highlight br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Click the areas below to toggle the "#{$active-class-name}" state.</span></div> Set the font color of the child by setting the parent to .#{$active-class-name}. `a:c_primary`

  c_primary              - text color modified light to dark
  c_shade              - text color modified light to dark
  c_info              - text color modified light to dark
  c_highlight              - text color success color
  c_success              - text color modified light to dark
  c_warning              - text color modified light to dark
  c_alert              - text color modified light to dark

  Markup:
  <div class="flex p_5 is-active_toggle-function c_white">
    <div class="flex_auto a:{{modifier_class}}-5 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">5</div>
    <div class="flex_auto a:{{modifier_class}}-4 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">4</div>
    <div class="flex_auto a:{{modifier_class}}-3 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">3</div>
    <div class="flex_auto a:{{modifier_class}}-2 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">2</div>
    <div class="flex_auto a:{{modifier_class}}-1 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">1</div>
    <div class="flex_auto a:{{modifier_class}}-0 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">0</div>
    <div class="flex_auto a:{{modifier_class}}-n1 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">n1</div>
    <div class="flex_auto a:{{modifier_class}}-n2 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">n2</div>
    <div class="flex_auto a:{{modifier_class}}-n3 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">n3</div>
    <div class="flex_auto a:{{modifier_class}}-n4 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">n4</div>
    <div class="flex_auto a:{{modifier_class}}-n5 font_bold bg_shade-n3 font_5 font_accent: text_center br_1 br_solid br_shade-4 p_1">n5</div>
  </div>

  Weight: 10
 
  Styleguide Interaction.States.Active.text
 
 */
/*
  #{$active-class-name} Background Color
 
  <div class="p-b_3"><span class="bg_highlight br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Click the areas below to toggle the "#{$active-class-name}" state.</span></div> Set the font color of the child by setting the parent to .#{$active-class-name}. `a:c_primary`

  bg_primary              - text color modified light to dark
  bg_shade              - text color modified light to dark
  bg_info              - text color modified light to dark
  bg_highlight              - text color success color
  bg_success              - text color modified light to dark
  bg_warning              - text color modified light to dark
  bg_alert              - text color modified light to dark

  Markup:
  <div class="flex p_5 is-active_toggle-function">
    <div class="flex_auto a:{{modifier_class}}-5 bg_shade-2 font_3  text_center br_1 br_solid br_shade-4 p_1">5</div>
    <div class="flex_auto a:{{modifier_class}}-4 bg_shade-2 font_3  text_center br_1 br_solid br_shade-4 p_1">4</div>
    <div class="flex_auto a:{{modifier_class}}-3 bg_shade-2 font_3  text_center br_1 br_solid br_shade-4 p_1">3</div>
    <div class="flex_auto a:{{modifier_class}}-2 bg_shade-2 font_3  text_center br_1 br_solid br_shade-4 p_1">2</div>
    <div class="flex_auto a:{{modifier_class}}-1 bg_shade-2 font_3  text_center br_1 br_solid br_shade-4 p_1">1</div>
    <div class="flex_auto a:{{modifier_class}}-0 bg_shade-2 font_3  text_center br_1 br_solid br_shade-4 p_1">0</div>
    <div class="flex_auto a:{{modifier_class}}-n1 bg_shade-2  font_3  text_center br_1 br_solid br_shade-4 p_1">n1</div>
    <div class="flex_auto a:{{modifier_class}}-n2 bg_shade-2  font_3  text_center br_1 br_solid br_shade-4 p_1">n2</div>
    <div class="flex_auto a:{{modifier_class}}-n3 bg_shade-2  font_3  text_center br_1 br_solid br_shade-4 p_1">n3</div>
    <div class="flex_auto a:{{modifier_class}}-n4 bg_shade-2  font_3  text_center br_1 br_solid br_shade-4 p_1">n4</div>
    <div class="flex_auto a:{{modifier_class}}-n5 bg_shade-2  font_3  text_center br_1 br_solid br_shade-4 p_1">n5</div>
  </div>

  Weight: 10
 
  Styleguide Interaction.States.Active.background color
 
 */

/*
  #{$active-class-name} Icons
 
  <div class="p-b_3"><span class="bg_highlight br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg">Click the areas below to toggle the "#{$active-class-name}" state.</span></div> Toggle the display and hide of a an icon with applying `is-active_icon-show` or `is-active_icon-hide`  

  fa-award            - toggle the award icon
  fa-bell             - toggle the bell icon
  fa-bell-slash       - toggle the bell slash icon
  fa-bug              - toggle the bug icon


  Markup:
  <div class="flex is-active_toggle-function">
    <div class="flex_auto a:bg_accent bg_shade-2 font_3 text_center br_1 br_solid br_shade-4 p_3"><i class="fas font_4 {{modifier_class}} a:icon-show"></i> Show When Active</div>
    <div class="flex_auto a:bg_accent bg_shade-2 font_3 text_center br_1 br_solid br_shade-4 p_3"><i class="fas font_4 {{modifier_class}} a:icon-hide"></i> Hide when Active</div>
  </div>

  Weight: 10
 
  Styleguide Interaction.States.Active.icon
 
 */
 @include is-active-loop-transparent('background-transparent', 'white');
 @include is-active-loop-transparent('color-transparent', 'white');
 @include is-active-loop-transparent('background-transparent', 'black');
 @include is-active-loop-transparent('color-transparent', 'black');

@each $color-name, $color in $theme-palette {
  @include is-active-loop('background-transparent', $color-name, $color);
	@include is-active-loop('color-transparent', $color-name, $color);
}

.a\:icon-show,
.#{$active-class-name}.a\:icon-hide,
.#{$active-class-name} > .a\:icon-hide {
	display: none !important;
}
.#{$active-class-name} > .a\:icon-show,
.#{$active-class-name}.a\:icon-show {
	display: inline-block !important;
}

.a\:visible,
#{class-name-constructor(display,none,"",is-active)},
#{class-name-constructor(display,none,"",is-active-self)} {
	display: none !important;
}
#{class-name-constructor(display,inline,"",is-active)},
#{class-name-constructor(display,inline,"",is-active-self)} {
	display: inline !important;
}
#{class-name-constructor(display,inline-block,"",is-active)},
#{class-name-constructor(display,inline-block,"",is-active-self)} {
	display: inline-block !important;
}
#{class-name-constructor(display,block,"",is-active)},
#{class-name-constructor(display,inline-block,"",is-active-self)} {
	display: block !important;
}

/*
#{$active-class-name} Display

<div class="p-b_3"><span class="bg_highlight br_radius c_white inline-block font_n1 p-7_3 p-x_5 sg"> Click the areas below to toggle the "#{$active-class-name}" state.</span></div>Toggle the display type of an object when it's parent '#{$active-class-name}'

none            -  make the display none when parent is-active
inline          -  make the display inline when parent is-active
inline-block    -  make the display inline-block when parent is-active
block           -  make the display block when parent is-active


Markup:
<div class="p_5 is-active_toggle-function">
  <span class="a:bg_accent bg_shade-2 font_4 text_center br_1 br_solid br_shade-4 p_4">Click to Toggle parent is-active</span>
  <span class="a:{{modifier_class}} a:bg_accent bg_shade-2 font_4 text_center br_1 br_solid br_shade-4 p_4">Span with display:{{modifier_class}} when parent is active</span>
</div>

Weight: 10

Styleguide Interaction.States.Active.display

*/
