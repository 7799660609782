/*
User Select
 
User interaction classes that define how the user can interact with the text of the element. 

.user-select_all - User on click will select All
.user-select_none - User on click will select Nothing
.user-select_auto - User on click will select Browser Default
.user-select_text - User on click will select Only the text

 
Weight:0
 
Styleguide Interaction.User.UserSelect
*/

.user-select_all{
    user-select: all
} 
.user-select_none{
    user-select: none
} 
.user-select_auto{
    user-select: auto
} 
.user-select_text{
    user-select: text
} 