.display_none\:screen{
    display:none !important;
}
@media only print{
    .display_none\:print{
        display:none !important;
    }
    .block\:print{
        display:block !important;
    }
    .inline\:print{
        display:inline !important;
    }
    .inline-block\:print{
        display:inline-block !important;
    }
    .table\:print{
        display:table !important;
    }
    .flex\:print{
        display:flex !important;
    }
}